span{
  text-decoration-color: $sub-color !important;
  text-decoration-thickness: 3px !important;
}
a{
  color: #5195de;
  text-decoration-color: #5195de;
}
h1{
  font-size: 36px;
  font-weight: bold;
  margin: 32px 0 32px;
  border-bottom: 1px solid $main-color;
  line-height: 1.8;
  @include mq-down(pc) {
    font-size: 24px;
  }
}
h2{
  padding: 8px 0 8px 24px;
  border-left: 3px solid #070a49;
  font-size: var(--title-font-size);
  font-weight: 700;
  margin: 32px 0 32px;
  position: relative;
}
h3{
  margin: 44px 0 32px;
  font-size: 16px;
  font-weight: $bold-font-weight;

  @include mq-up(pc) {
    margin: 60px 0 30px;
    font-size: 24px;
  }
}
h4{
  margin: 30px 0;
  font-size: 16px;
  font-weight: $bold-font-weight;
  padding-left: 20px;
  position: relative;
  &:before{
    content: '';
    display: block;
    width: 10px;
    height: 3px;
    transform: translate(0,-50%);
    top:50%;
    background: #070a49;
    left: 0;
    position: absolute;
  }

  @include mq-up(pc) {
    margin: 40px 0 30px;
    font-size: 24px;
  }
}
h5{
  margin: 20px 0;
  font-size: 14px;
  font-weight: $bold-font-weight;
  position: relative;
  @include mq-up(pc) {
    margin:40px 0 30px;
    font-size: 18px;
  }
}
h6 {
  margin: 20px 0;
  font-size: 14px;
  font-weight: $bold-font-weight;
  position: relative;
  @include mq-up(pc) {
    margin: 40px 0 30px;
    font-size: 16px;
  }
}
table{
  max-width: 100%;
  th,td{
    border: 1px solid #070a49;
    padding: 5px 10px;
  }
  th{
    background: #070a49;
    color: white;
    text-align: center;
  }
}
ul{
  li{
    padding-left: 1.3em;
    position: relative;
    &:before{
      content: '';
      left: 0;
      top: .7em;
      width: 10px;
      height: 10px;
      background: $main-color;
      position: absolute;
    }
  }
}
ol{
  counter-reset: section;
  li{
    padding-left: 1.5em;
    position: relative;
    &:before{
      counter-increment: section;
      content: counter(section) ".";
      left: 0;
      top: 0;
      position: absolute;
    }
  }
}
a{
  //color: $key-color;
  text-decoration: underline;
}

p {
  font-size: 15px;

  @include mq-up(pc) {
    font-size: 16px;
    line-height: 1.8;
  }
}

img {
  width: 100%;
  max-width: 100%;
  margin: 44px 0;
  display: block;
  height: auto;

  @include mq-up(pc) {
    margin: 60px 0;
  }
}

strong,b{
  font-weight: bold;
}

.wp-block-column{
  img{
    margin-top: 0;
    margin-bottom: 0px;
    @include mq-up(pc) {
      margin: 0 0 30px;
    }
  }

}
.wp-block-button a{
  text-decoration: none;
}

.wp-block-table {
  width: 100%;
  margin-left: -35px;
  padding-left: 35px;
  padding-right: 35px;
  @include mq-up(pc) {
    width: initial;
  }
  table{
    min-width: 500px;
    width: initial;
  }
}
.is-provider-youtube{
  iframe{
    max-width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }

}