.hp-price{
  letter-spacing: 1px;
  padding-bottom: 80px;
  &__localNav{

  }
  .b-plan{
    margin-bottom: 0;
    padding: 0;
    &:before{
      content: none;
    }
    .button{
      position: absolute;
      z-index: 50;
      transform: translateX(-50%);
      left: 50%;
      bottom: 40px;
    }
    &__item{
      padding-bottom: 110px;
    }
  }


  &__lists{
    padding: 40px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq-down(m){
      gap: 15px;
      justify-content: center;
    }

  }

  &__listItem{
    width: 32%;
    @include mq-down(m){
      width: 100%;
      max-width: 400px;
    }
    &__detail{
      background: $main-color;
      color: $white;
      font-weight: 500;
      display: block;
      text-align: center;
      padding:10px 10px;
      box-sizing: border-box;
      font-size: 2.0rem;
      @include mq-down(tab){
        font-size: 1.6rem;
      }
    }
    &__caution{
      margin-top: 20px;
      font-size: 1.2rem;
    }
  }
}