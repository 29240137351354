// 余白ブロック

.b-spaceBlock__large {
  padding-bottom: 80px;
  display: block;
}

.b-spaceBlock__middle {
  padding-bottom: 60px;
  display: block;
}

.b-spaceBlock__small {
  display: block;
  padding-bottom: 30px;
}


// バックグラウンド
.b-background{
  padding: 20px;
  margin: 30px 0;
  @include mq-up(pc) {
    padding: 30px;
  }
}
.b-btn_wrap{
  margin: 20px 0;
  text-align: center;
}
.b-edit_btn{
  background: #ff6b00;
  color: $white !important;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  padding:8px 35px;
  letter-spacing: 1px;
  font-size: 16px;
  margin-top: 0;
  border-radius: 50px;
  border-bottom: 3px solid #b64f00;
  &:hover{
    margin-top: 3px;
    border-bottom: 0;
  }
}

.wp-block-quote{
  position: relative;
  background: #eff0f3;
  padding:20px 60px;

  &:before{
    content: "“";
    font-family: 'ヒラギノ角ゴ', sans-serif;
    font-size: 80px;
    position: absolute;
    color: #7472ae;
    left: 10px;
    top:30px;
  }
}

.b-check_point{
  border: 2px solid #070a49;
  &__title{
    padding:10px 40px;
    background: #070a49;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    &:before{
      content:'';
      width: 10px;
      height: 20px;
      display: inline-block;
      border-bottom: 4px #ffeb0a solid;
      border-right: 4px #ffeb0a solid;
      transform: rotate(45deg);
      margin-right: 20px;
      margin-left: -40px;
    }
    @include mq-down(pc) {
      font-size: 16px;
      &:before{
        width: 8px;
        height: 16px;
      }
    }
  }
  &__edit{
    padding: 20px;
  }
}

.b-bubble{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &--dt{
      width: 120px;
    }
    &--dd{
      width: calc(100% - 150px);
      background: #eff0f3;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
    }
    &--dt.is-right{
      order:2;
    }
    &--dd.is-right:after{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: #eff0f3;
        transform: translate(0,-50%) rotate(45deg);
        position: absolute;
        top:50%;
        left: calc(100% - 10px);
    }
    &--dd.is-left:after{
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: #eff0f3;
      transform: translate(0,-50%) rotate(45deg);
      position: absolute;
      top:50%;
      right: calc(100% - 10px);
    }
    &--img{
      width: 100px;
      height: 100px;
      margin: 0;
      margin-bottom: 5px;

      border-radius: 50%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
    &--name{
      text-align: center;
      font-weight: bold;
    }



  }

}
.b-videoBlock{
  padding: 80px 0;
}
.b-videoBlock__iframe{
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 980px;
  aspect-ratio: 16 / 9;
}
@include mq-down(pc) {
  .b-videoBlock{
    padding: 40px 0;
  }
  .b-videoBlock__iframe{
    width: 90%;
  }
}

.b-docswellBlock{
  padding: 80px 0;
  text-align: center;
  iframe{

  }
  .docswell-iframe-wrapper > div{
    max-width: 980px !important;
  }
}
@include mq-down(pc) {
  .b-docswellBlock{
    padding: 40px 0;
  }
  .docswell-iframe-wrapper > div{
    width: 90%;
  }
}
