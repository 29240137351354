.hp-support{
  padding-bottom: 80px;
  .c-inner{
    @include mq-down(m){
      overflow: hidden;
    }
  }
  .b-flow{
    margin-bottom: 40px;
    &__img{
      margin-bottom: 20px;
    }
    &__title{
      min-height: initial;
    }
    &__txt{
      font-size: 1.4rem;
    }

  }
  .c-edit{
    p{
      line-height: 1.8 !important;
    }
    img{
      margin: 0 auto !important;
    }
    h3{
      margin-top: 0;
      margin-bottom: 20px;
    }

  }
  .b-student__container{
    padding-top: 0;
    @include mq-down(m){
      overflow: initial !important;
    }
  }
  .b-student:before{
    width: 100vw;
    @include mq-down(m){
      width: 110vw;
    }
  }
  .main__course__point-list__item{
    padding-left: 0;
  }

  &__slider{

    &__img{
      object-fit: cover;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
    }

    &__txt{
      margin-top: 15px;
      text-align: center;
      font-weight: 600;
      font-size: 1.4rem;
    }
  }


}