.hp-top{
  .is-gray{
    background: #f0f0f0;

  }
  .b-student__container{
    padding-top: 0;
    @include mq-down(m){
      overflow: initial !important;
    }
  }
  .main__container__contents__copy-wrap__img{
    @include mq-down(m){
      margin-top: 40px;
    }
  }
  .main__container__inner{
    @include mq-down(m){
      padding: 0 5%;
    }
  }

  .b-flow{
    margin-bottom: 40px;
    &__img{
      margin-bottom: 20px;
    }
    &__title{
      min-height: initial;

    }
    &__txt{
      font-size: 1.6rem;
    }

    &.is-top{
      @include mq-down(m){
        gap: 10px;
        //margin-bottom: 0;
        .b-flow__item{
          width: calc(50% - 10px);
        }
        .b-flow__txt{
          font-size: 1.3rem;
        }
      }
    }
  }

  &__title-sub{
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    @include mq-down(m){
      gap: 5px;
    }
    span{
      font-size: 2rem;
      font-weight: bold;
      background: #c6c6e3;
      padding: 5px 10px;
      @include mq-down(m){
        font-size: 1.2rem;
        padding: 2px 5px;
      }
    }
  }
}