//$font-family: 'Rubik',rubik, noto-sans, sans-serif;
//$font-family-faq: proxima-nova, rubik, noto-sans, sans-serif;
$font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font-family-faq: proxima-nova, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
$light-font-weight: 500;
$base-font-weight: 500;
$medium-font-weight: 500;
$bold-font-weight: 700;

$main-color: #070a49;
$key-color: #ffa700;
$sub-color: #ffeb0a;
$blue: #1e2498;
$medium-blue: #8f93cc;
$light-blue: #c8cae6;
$back-color: #eff0f3;
$gray: #d1d1d1;
$gray-back: #eeeeee;
$red: #de4343;
$white: #ffffff;

$breakpoint-up: (
  "tab": "screen and (min-width: 580px)",
  "m": "screen and (min-width: 768px)",
  "pc": "screen and (min-width: 1080px)",
  "xl": "screen and (min-width: 1200px)",
) !default;

$breakpoint-down: (
  "tab": "screen and (max-width: 579px)",
  "m": "screen and (max-width: 767px)",
  "pc": "screen and (max-width: 1079px)",
  "xl": "screen and (max-width: 1199px)",
) !default;
@mixin mq-up($breakpoint: tab) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}
@mixin mq-down($breakpoint: tab) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}
@mixin link-opacity($opacity: 0.9) {
  opacity: $opacity;
  transition-duration: 0.5s;
}

@mixin pc-width($margin-h: 0) {
  width: 77%;
  max-width: 1200px;
  margin: $margin-h auto;
}
:root {
  --minimum-font-size: 10px;
  --base-font-size: 13px;
  --base-font-size-eng: 14px;
  --middle-font-size: 16px;
  --title-font-size: 20px;
  --max-font-size: 24px;
  --font-line-height: 28px;

  @include mq-up(pc) {
    --minimum-font-size: 13px;
    --base-font-size: 16px;
    --base-font-size-eng: 16px;
    --middle-font-size: 16px;
    --title-font-size: 28px;
    --max-font-size: 40px;
    --font-line-height: 36px;
  }
}
.main {
  @import "./module/swiper";
  font-size: 16px;
  letter-spacing: 0;
  box-sizing: border-box;
  overflow: hidden;
  img {
    max-width: 100%;
    margin: 0 0;
  }

  @mixin border-box($color: $main-color) {
    border: solid 2px $color;
  }

  @mixin line-title($font: var(--title-font-size)) {
    padding-left: 10px;
    border-left: solid 2px $main-color;
    font-size: $font;
    font-weight: $bold-font-weight;

    @include mq-up(pc) {
      padding: 8px 0 8px 24px;
      border-left: 3px solid $main-color;
    }
  }

  @mixin border-box($color: $main-color) {
    border: solid 2px $color;
  }

  @mixin line-title($font: var(--title-font-size)) {
    padding-left: 10px;
    border-left: solid 2px $main-color;
    font-size: $font;
    font-weight: $bold-font-weight;

    @include mq-up(pc) {
      padding: 8px 0 8px 24px;
      border-left: 3px solid $main-color;
    }
  }



  body {
    width: 100%;
    font: 100% $font-family;
    color: $main-color;
    font-size: var(--base-font-size);
    font-weight: $base-font-weight;
    line-height: var(--font-line-height);
  }
  a {
    color: $main-color;
    cursor: pointer;
    text-decoration: none;
  }

  .sp {
    display: block;
    @include mq-up(tab) {
      display: none;
    }
  }

  .pc {
    display: none;
    @include mq-up(pc) {
      display: block;
    }
  }

  .tab {
    display: block;
    @include mq-up(pc) {
      display: none;
    }
  }

  .m {
    display: none;
    @include mq-up(m) {
      display: block;
    }
  }

  .m-sp {
    display: none;
    @include mq-down(m) {
      display: block;
    }
  }

  .xl {
    display: none;
    @include mq-up(xl) {
      display: block;
    }
  }

  .under-line {
    border-bottom: 2px solid;

    @include mq-up(pc) {
      border-bottom: 3px solid;
    }
  }

  .shadow {
    box-shadow: 2px 2px 6px rgba($color: $main-color, $alpha: 0.3);
    transition-duration: 0.3s;
  }

  .font-size-base {
    font-size: var(--base-font-size);
    font-weight: $bold-font-weight;
  }

  .font-size-16 {
    font-size: var(--middle-font-size);
  }

  .font-size-18 {
    font-size: 18px;
  }

  .font-size-32 {
    font-size: 32px;
  }

  .bc-main-color {
    background-color: $main-color;
  }

  .bc-sub-color {
    background-color: $sub-color;
  }

  .bc-back-color {
    background-color: $back-color;
  }

  .bc-blue {
    background-color: $blue;
  }

  .bc-medium-blue {
    background-color: $medium-blue;
  }

  .bc-light-blue {
    background-color: $light-blue;
  }

  input.button.wide-button-pc {
    padding: 20px 0;
  }

  .button {
    min-width: 261px;
    //height: 48px;
    margin: 0 auto;
    @include border-box($main-color);
    display: block;
    position: relative;
    transition-duration: 0.3s;
    line-height: 1.4;
    border-radius: 50px;
    //display: inline-block;
    width: fit-content;

    &:not(.button-white) {
      color: $main-color;
      background: $white;
      font-weight: bold;
    }

    &.wide-button-pc {
      @include mq-up(pc) {
        min-width: 406px;
      }
    }

    &:hover {
      background-color: $main-color;
      color: $white;
      transition-duration: 0.5s;
    }

    @include mq-up(pc) {
      min-width: 250px;
      //height: 56px;

    }

    &__text {
      padding: 10px 20px;
      text-align: center;
      font-weight: $bold-font-weight;

      @include mq-up(pc) {
        padding: 12px 35px;
      }
    }

    &__arrow {
      position: absolute;
      right: 16px;
      transform: translateY(-50%);
      top: 50%;

      @include mq-up(pc) {
        width: 16px;
        right: 18px;
      }
    }
  }

  .button-orange {
    width: 321px;
    border: none;
    background-color: $key-color;
    height: 48px;
    margin: 0 auto;
    border-radius: 24px;
    display: block;
    position: relative;
    transition-duration: 0.3s;

    &:hover {
      box-shadow: 2px 2px 20px rgba($color: $main-color, $alpha: 0.5);
      @include link-opacity();
    }

    @include mq-up(pc) {
      width: 406px;
      height: 56px;
      border-radius: 28px;
    }
  }

  .button-white {
    color: $white;
    border: solid 2px $white;

    &:hover {
      background-color: $white;
      color: $main-color;
      transition-duration: 0.5s;
    }
  }

  .circle-text {
    font-size: 0.5rem;
  }

  .category-wrap-block {
    display: none;
    width: 90px;
    @include mq-up(m) {
      display: block;
    }
  }

  .category-wrap {
    width: 90px;
    border: solid 1px $main-color;
    border-radius: 13px;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 13px;

    &:not(:first-child) {
      margin-top: 5px;
    }

    @include mq-up(m) {
      display: flex;
    }

    @include mq-up(pc) {
      height: 24px;
    }
  }

  //--- blog のメニュー ---
  .focus {
    opacity: 1 !important;
    border-bottom: 2px solid $main-color;
  }

  //--- students voice のメニュー ---
  .focus-sv {
    background-color: $main-color;
    color: $white;
  }

  .left-margin-44 {
    margin-left: 44px;
  }

  .left-margin-27 {
    margin-left: 27px;

    @include mq-up(pc) {
      margin-left: 0;
    }
  }

  .right-margin-27 {
    margin-right: 27px;

    @include mq-up(pc) {
      margin-right: 0;
    }
  }

  .flex-right {
    display: flex;
    flex-direction: row-reverse;

    @include mq-up(m) {
      display: block;
      margin-right: 27px;
    }

    @include mq-up(pc) {
      margin-right: 0;
    }
  }

  .white {
    color: $white;
  }

  .blue {
    color: $blue;
  }

  .red {
    color: $red;
  }

  //----- hamburger-menu -----

  .in {
    transform: translateX(-100%);
  }



  //----- /hamburger-menu -----

  //----- carousel -----

  .carousel {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      scroll-snap-align: center;
    }
  }

  .carousel-button {
    width: 100%;
    padding: 0 7px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 270px;

    &__button {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $main-color;

      &__icon {
        display: block;
        width: 16px;
        margin: 0 auto;
        padding-top: 10px;
      }
    }
  }

  //----- /carousel -----

  //----- accordion menu -----

  .accordion-wrapper {
    // display: none;
    height: 0;
    overflow: hidden;
    opacity: 0;
    // transition-duration: 0.7s;
    transition: all 0.7s ease-out;
  }

  .accordion-wrapper.is-active {
    // display: block;
    height: auto;
    opacity: 1;
    // transition-duration: 0.5s;
    transition: all 0.5s ease-out;
  }

  //----- /accordion menu -----

  // ----- ブログ -----

  @mixin blog-img($width: 100%) {
    width: $width;
    height: 168px;
    object-fit: cover;
  }

  @mixin blog-date() {
    padding: 24px 0 0 24px;
    font-size: 14px;
    opacity: 0.5;
  }

  @mixin blog-title() {
    padding: 0 24px;
    font-size: var(--middle-font-size);
    line-height: 22px;
    font-weight: $bold-font-weight;
  }

  @mixin blog-category() {
    width: 136px;
    height: 24px;
    margin: 10px 12px 0 24px;
    @include border-box();
    border-radius: 12px;
    text-align: center;

    @include mq-up(pc) {
      font-size: 13px;
    }
  }

  .blog-list {
    margin: 0 18px;

    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &:after {
      content: '';
      display: block;
      width: 288px;
      margin: 0 9px;
      @include mq-up(pc) {
        width: 30%;
        margin: 0;
      }
    }

    &__item {
      width: 288px;
      min-width: 280px;
      min-height: 336px;
      margin: 0 9px;
      padding-bottom: 10px;
      background-color: $white;
      display: block;
      transition-duration: 0.3s;

      &:hover {
        @include link-opacity(0.8);
      }

      @include mq-up(pc) {
        padding-bottom: 0;
        width: 30%;
        height: 336px;
        margin: 0;
      }

      &__img-wrap {
        overflow: hidden;

        &__img {
          width: 288px;
          height: 168px;
          object-fit: cover;
          transition-duration: 0.7s;

          &:hover {
            transform: scale(1.2, 1.2);
            transition-duration: 0.5s;
          }

          @include mq-up(pc) {
            width: 100%;
          }
        }
      }

      &__date {
        @include blog-date();
      }

      &__title {
        @include blog-title();
      }

      &__category-wrap {
        //width: 138px;
        width: fit-content;
        height: 24px;
        padding: 0 20px;
        margin: 10px 12px 0 24px;
        border: solid 1px $main-color;
        border-radius: 13px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq-up(pc) {
          font-size: 13px;
        }
      }
    }
  }

  .blog-list-wide {
    @include mq-up(m) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &:after {
      content: '';
      width: 100%;
      display: block;
      @include mq-up(m) {
        width: 288px;
      }

      @include mq-up(pc) {
        width: 30%;
      }
    }

    &__item {
      width: 100%;
      height: 336px;
      margin-top: 34px;
      background-color: $white;
      display: block;

      &:hover {
        @include link-opacity(0.8);
      }

      @include mq-up(pc) {
        margin-top: 80px;
      }

      @include mq-up(m) {
        width: 288px;
      }

      @include mq-up(pc) {
        width: 30%;
      }

      &__img-wrap {
        overflow: hidden;

        &__img {
          @include blog-img();
          transition-duration: 0.7s;

          &:hover {
            transform: scale(1.2, 1.2);
            transition-duration: 0.5s;
          }
        }
      }

      &__date {
        @include blog-date();
      }

      &__title {
        @include blog-title();
      }

      &__category-wrap {
        @include blog-category();
      }
    }
  }

  //----- /ブログ ------

  // ----- 卒業インタビュー -----

  .interview-list {
    margin-bottom: 44px;

    @include mq-up(pc) {
      margin-bottom: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      max-width: 474px;
      padding: 8px;
      margin: 0 auto 22px;
      display: flex;
      border: solid 2px $main-color;
      background-color: $white;
      position: relative;
      transition-duration: 0.7s;
      flex-wrap: wrap;

      &:hover {
        background-color: $gray-back;
        transition-duration: 0.5s;
      }

      @include mq-up(pc) {
        width: 48%;
        max-width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 20px 40px 20px 20px;
        margin: 0 0 36px;
      }

      &__info {
        margin-left: 10px;
        font-weight: $bold-font-weight;
        font-size: var(--minimum-font-size);
        width: calc(100% - 110px);
        @include mq-up(pc) {
          margin-left: 30px;
          width: calc(100% - 190px);
        }

        &__school {
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 26px;
            width: 18px;
            height: 2px;
            background-color: $main-color;

            @include mq-up(pc) {
              width: 36px;
              top: 42px;
            }
          }
        }

        &__title {
          line-height: 20px;
          margin-top: 10px;

          @include mq-up(pc) {
            margin-top: 31px;
            margin-bottom: 12px;
            line-height: 22px;
          }
        }
      }

      &__img-wrap {
        width: 100px;
        height: 115px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include mq-up(pc) {
          width: 160px;
          height: 160px;
        }

        &__img {

          object-fit: cover;


        }
      }

      &__link-icon {
        width: 26px;
        height: 26px;
        border: solid 2px $main-color;
        position: absolute;
        bottom: -2px;
        right: -2px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq-up(pc) {
          width: 39px;
          height: 39px;
        }

        &__icon {
          width: 9px;
          display: block;

          @include mq-up(pc) {
            width: 14px;
          }
        }
      }
    }
  }

  // ----- /卒業インタビュー -----
  // ----- ニュースリスト -----

  .news-list {
    &__item {
      margin-bottom: 18px;
      padding-bottom: 14px;
      display: flex;
      border-bottom: 1px solid $main-color;
      line-height: 18px;
      transition-duration: 0.3s;

      &__date {
        font-size: var(--base-font-size-eng);
      }

      &__title {
        padding-left: 26px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // ----- /ニュースリスト -----

  // ----- 校舎一覧 -----

  .school-list {
    margin: 44px 0 55px;

    @include mq-up(pc) {
      margin: 60px 0 100px;
    }

    &:last-child {
      border-bottom: 2px solid $main-color;
    }

    &__item {
      padding: 16px 0 14px;
      display: flex;
      align-items: center;
      border-top: solid 2px $main-color;
      transition-duration: 0.3s;

      &:hover {
        background-color: $gray-back;
        transition-duration: 0.5s;
      }

      @include mq-up(pc) {
        padding: 40px 0;
      }

      &__icon {
        width: 10px;
        display: block;

        @include mq-up(m) {
          width: 15px;
        }
      }

      &__school-name {
        margin-left: 20px;
        font-size: 14px;
        font-weight: $bold-font-weight;
        width: 80px;

        @include mq-up(m) {
          width: 100px;
          font-size: 16px;
        }
      }

      &__address {
        padding-left: 20px;
        @include mq-up(m) {
          padding-left: 70px;
        }

      }
    }
  }

  //----- /校舎一覧 -----

  //----- pager -----
  .pager {
    margin: 44px 0 55px;
    display: flex;
    justify-content: center;

    @include mq-up(pc) {
      margin: 64px 0 120px;
    }

    .wp-pagenavi {
      display: flex;
      justify-content: center;
    }

    .pages, .first, .last {
      display: none;
    }

    &__item, .page, .current, .previouspostslink, .nextpostslink, .extend {
      width: 30px;
      height: 30px;
      margin: 0 4px;
      display: flex;
      border: solid 2px $main-color;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      @include mq-up(pc) {
        width: 44px;
        height: 44px;
        margin: 0 7px;
        border-radius: 50%;
      }
    }

    .current {
      color: white;
      background: $main-color;
      font-weight: normal !important;
    }
  }

  //----- /pager -----

  //---------- main ----------


  //---------- /main ----------

  //---------- price page ----------

  .main__price {
    padding: 0 27px;

    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto;
    }

    &__copy {
      text-align: center;
      font-size: var(--middle-font-size);
      font-weight: $bold-font-weight;

      @include mq-up(pc) {
        font-size: 24px;
      }
    }

    &__course-merit {
      margin-top: 44px;

      @include mq-up(pc) {
        margin-top: 60px;
      }

      &__top {
        @include border-box($gray);

        &__text {
          padding: 30px 0 10px;
          text-align: center;
          font-weight: $bold-font-weight;

          @include mq-up(pc) {
            padding: 42px 0 21px;
          }
        }

        &__price {
          padding-bottom: 30px;
          font-size: 32px;
          font-weight: $bold-font-weight;
          text-align: center;

          @include mq-up(pc) {
            padding-bottom: 42px;
          }

          &__font-weight {
            font-weight: 200;
          }

          &__font-small {
            font-size: var(--base-font-size-eng);
          }
        }
      }

      &__list {
        margin-top: -2px;
        padding: 0 27px;
        @include border-box($gray);

        @include mq-up(pc) {
          display: flex;
          justify-content: space-around;
        }

        &__item {
          @include mq-up(pc) {
            width: 20%;
            padding: 42px;
          }

          &__title {
            padding-top: 30px;
            font-weight: $bold-font-weight;
            font-size: var(--middle-font-size);
          }

          &__text {
            padding: 16px 0 32px 0;
           
          }

          &__sp-line {
            @include mq-up(pc) {
              display: none;
            }
          }
        }
      }
    }

    &__course {
      margin-top: 55px;

      @include mq-up(pc) {
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
      }

      &__item {
        margin-bottom: 55px;

        @include mq-up(pc) {
          width: 49%;
        }

        &__intro {
          padding: 55px 27px;
          color: $white;
         

          @include mq-up(pc) {
            height: 310px;
            padding: 80px 60px;
          }

          &__title {
            margin-bottom: 34px;
            font-size: var(--title-font-size);
            font-weight: $bold-font-weight;

            @include mq-up(pc) {
              margin-bottom: 63px;
            }
          }

          &__text {
            @include mq-up(pc) {
              line-height: 40px;
            }
          }
        }

        &__price {
          height: 160px;
          margin-top: 32px;
          text-align: center;
          font-size: var(--base-font-size-eng);
          font-weight: $bold-font-weight;
          line-height: 33px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.9em !important;
          }

          @include mq-up(pc) {
            margin-top: 12px;
          }
        }

        &__points {
          height: 160px;
          margin: 32px 0;
          font-size: 12px;
          font-weight: $bold-font-weight;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mq-up(pc) {
            margin: 12px 0 30px;
          }

          &__text {
            &__icon {
              width: 12px;
              margin-top: 7px;

              @include mq-up(pc) {
                margin-top: 12px;
              }
            }
          }
        }
      }
    }
  }

  //---------- /price page ----------

  //---------- course page ----------

  .main__course {
    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto;
    }

    &__menus {
      padding: 0;
      display: flex;
      justify-content: space-between;

      @include mq-up(pc) {
        padding: 0;
      }

      &__item-color-main {
        width: 48%;
        height: 156px;
        @include border-box($main-color);
        transition-duration: 0.3s;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        &:hover {
          @include link-opacity(0.8);
        }

        @include mq-up(pc) {
          height: 180px;
        }

        &__title {
          font-size: var(--middle-font-size);
          font-weight: $bold-font-weight;
          text-align: center;
          font-size: 14px;
          @include mq-up(pc) {
            font-size: 24px;
          }
        }

        &__icon {
          display: block;
          margin: 20px auto;
          position: absolute;
          transform: translateX(-50%);
          bottom: 15px;
          left: 50%;
        }
      }

      &__item-color-blue {
        width: 48%;
        height: 156px;
        @include border-box($blue);
        transition-duration: 0.3s;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        &:hover {
          @include link-opacity(0.8);
        }

        @include mq-up(pc) {
          height: 180px;
        }

        &__title {
          font-size: var(--middle-font-size);
          font-weight: $bold-font-weight;
          text-align: center;
          font-size: 14px;
          @include mq-up(pc) {
            font-size: 24px;
          }
        }

        &__icon {
          display: block;
          margin: 20px auto;
          position: absolute;
          transform: translateX(-50%);
          bottom: 15px;
          left: 50%;
        }
      }
    }

    &__point-list {
      &__item {
        padding-left: 27px;

        @include mq-up(pc) {
          padding-left: 0;
        }

        &__title {
          margin: 44px 0 32px;
          @include line-title();

          @include mq-up(pc) {
            margin: 80px 0 42px;
          }
        }

        &__text {
          padding-right: 27px;
        }

        &__text-list {
          padding: 0 27px;

          &__text {
            list-style-type: disc;
          }
        }

        &__img-wrap {
          width: 100%;
          overflow-x: auto;

          &__img {
            width: 600px;
            display: block;
            max-width: initial;
            @include mq-up(m) {
              width: 100%;
              min-width: initial;
            }
          }
        }
      }
    }

    &__link-area {
      padding: 55px 27px;

      @include mq-up(pc) {
        padding: 100px 0 0;
      }

      &__link {
        width: 100%;
        height: 213px;
        display: flex;
        align-items: center;
        background-image: url(../dist/images/img_11.jpg);
        background-size: cover;
        background-position: center center;
        transition-duration: 0.3s;

        @include mq-up(pc) {
          height: 320px;
          background-position: 0 -350px;
        }

        &:hover {
          @include link-opacity(0.8);
        }

        &__text-area {
          padding-left: 27px;
          color: $white;

          @include mq-up(pc) {
            padding-left: 48px;
          }

          &__sub-title {
            font-size: var(--minimum-font-size);
            font-weight: $medium-font-weight;

            @include mq-up(pc) {
              font-size: 14px;
            }

            &::after {
              content: "";
              width: 17px;
              height: 1px;
              margin-bottom: 14px;
              display: block;
              background-color: $white;

              @include mq-up(pc) {
                width: 28px;
                height: 2px;
                margin: 16px 0 28px;
              }
            }
          }

          &__title {
            font-size: 18px;
            font-weight: $bold-font-weight;

            @include mq-up(pc) {
              font-size: 28px;
            }
          }
        }
      }
    }

    &__list-container {
      padding: 0 27px 66px;

      @include mq-up(pc) {
        padding: 100px 0 80px;
      }

      &__title {
        @include line-title();
        margin-bottom: 44px;
      }

      &__item {
        padding-bottom: 45px;
        margin-bottom: 34px;
        background-color: $back-color;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        @include mq-up(pc) {
          padding-bottom: 0;
          margin-bottom: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__img {
          width: 100%;

          @include mq-up(pc) {
            display: block;
            width: 50%;
          }
        }

        &__info {
          @include mq-up(pc) {
            width: 50%;
            padding: 58px 40px;
          }

          &__number {
            padding: 36px 0 26px;
            font-size: 24px;
            font-weight: $medium-font-weight;
            text-align: center;

            &::after {
              content: "";
              margin: 4px auto;
              display: block;
              width: 30px;
              height: 2px;
              background-color: $main-color;

              @include mq-up(pc) {
                margin: 10px 0 0;
              }
            }

            @include mq-up(pc) {
              padding: 0;
              text-align: left;
            }
          }

          &__title {
            padding-bottom: 20px;
            font-size: 18px;
            font-weight: $bold-font-weight;
            text-align: center;

            @include mq-up(pc) {
              text-align: left;
              padding: 20px 0;
            }
          }

          &__text {
            //padding: 0 27px;
           

            @include mq-up(pc) {
              padding: 0;
              text-align: left;
            }
          }
        }
      }
    }
  }

  //---------- /course page ----------

  //---------- employment support page ----------

  .main__es-page {
    &__list-container {
      &__item {
        &__title {
          padding: 16px 0 10px 0;
          margin: 0 27px;
          background-color: $main-color;
          color: $white;
          font-size: 15px;
          font-weight: $bold-font-weight;
          text-align: center;

          @include mq-up(pc) {
            @include pc-width();
            font-size: 20px;
          }
        }

        &__points {
          padding: 0 27px;

          @include mq-up(pc) {
            @include pc-width();
            padding: 0 0 60px;
          }

          &__item {
            margin-top: 44px;

            @include mq-up(pc) {
              margin-top: 80px;
            }

            &__title {
              margin-bottom: 12px;
              font-size: var(--middle-font-size);
              font-weight: $bold-font-weight;

              @include mq-up(pc) {
                margin-bottom: 30px;
                font-size: 28px;
              }
            }

            &__text {
             
            }
          }
        }

        &__order-list {
          margin: 55px 27px 0 27px;
          font-weight: $bold-font-weight;
          text-align: center;
          position: relative;

          @include mq-up(pc) {
            @include pc-width();
          }

          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            top: 13px;
            z-index: 1;
          }

          &__title {
            width: 107px;
            position: relative;
            margin: 0 auto;
            padding-bottom: 54px;
            font-size: var(--middle-font-size);
            background-color: $white;
            z-index: 2;

            @include mq-up(pc) {
              width: 160px;
              font-size: 20px;
            }
          }

          &__item {
            padding: 20px 0;
            @include border-box($gray);
            line-height: 18px;

            @include mq-up(pc) {
              padding: 26px 0 20px;
            }

            &__text {
              @include mq-up(pc) {
                font-size: 18px;
              }
            }

            &__icon {
              width: 23px;
              padding: 15px 0;
              margin: 0 auto;
              display: block;

              @include mq-up(pc) {
                padding: 20px 0;
              }
            }
          }
        }

        &__points-wide {
          margin-bottom: 76px;

          &:last-child {
            margin-bottom: 52px;

            @include mq-up(pc) {
              margin-bottom: 100px;
            }
          }

          &__item {
            margin-top: 56px;

            @include mq-up(pc) {
              margin-top: 80px;
            }

            &__title {
              padding: 0 27px;
              font-size: var(--middle-font-size);
              font-weight: $bold-font-weight;

              @include mq-up(pc) {
                @include pc-width();
                font-size: 28px;
              }
            }

            &__text {
              padding: 20px 27px 0;

              @include mq-up(pc) {
                @include pc-width();
                padding: 32px 0 0;
              }
            }

            &__img-wrap {
              width: 100%;
              height: 140px;
              margin-top: 44px;
              background-position: 0 -23px;

              @include mq-up(m) {
                height: 280px;
                background-position: 0 -30px;
              }

              @include mq-up(pc) {
                height: 360px;
                margin-top: 52px;
                background-position: 0 -60px;
              }
            }
          }

          &__line {
            margin: 56px 27px;
            border: 3px solid rgba($color: $main-color, $alpha: 0.2);

            @include mq-up(pc) {
              @include pc-width(61px);
            }
          }
        }
      }
    }

    &__human-list {
      padding: 0 27px;

      @include mq-up(pc) {
        @include pc-width();
        margin-bottom: 100px;
      }

      &__title {
        margin-bottom: 42px;
        @include line-title(16px);

        @include mq-up(pc) {
          font-size: 24px;
        }
      }

      &__list-container {
        @include mq-up(m) {
          display: flex;
          justify-content: space-between;
        }

        &__item {
          width: 100%;
          margin: 0 auto 56px;

          @include mq-up(m) {
            width: 30%;
            margin: 0;
          }

          &__img {
            width: 100%;
            display: block;
            margin: 0 auto;
          }

          &__title {
            margin-top: 20px;
            text-align: center;
            font-weight: $bold-font-weight;

            &::after {
              content: "";
              display: block;
              width: 27px;
              height: 2px;
              background-color: $main-color;
              margin: 16px auto;
            }
          }

          &__text {
            padding-top: 6px;
           
          }
        }
      }
    }
  }

  //---------- /employment support page ----------

  //---------- school-list ----------

  .main__school-list {
    padding: 0 27px;

    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      padding: 0;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 12px;
      font-size: var(--middle-font-size);
      font-weight: $bold-font-weight;

      @include mq-up(pc) {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
  }

  //---------- /school-list ----------

  //---------- FAQ ----------

  .main__faq {
    padding: 0 27px;

    @include mq-up(pc) {
      @include pc-width();
      padding: 0;
    }

    &__list-container {
      margin-bottom: 66px;

      @include mq-up(pc) {
        margin-bottom: 160px;
      }

      &:last-child {
        border-bottom: 2px solid $main-color;
      }

      &__item {
        border-top: solid 2px $main-color;
        cursor: pointer;
        transition-duration: 0.3s;

        &:hover {
          background-color: $gray-back;
          transition-duration: 0.5s;
        }

        @include mq-up(pc) {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }

        &__qa {
          padding: 20px 0 20px 14px;
         

          @include mq-up(pc) {
            width: 80%;
            padding: 54px 0 54px 20px;
          }

          &__inner-item {
            display: flex;
            align-items: baseline;

            &:last-child {
              padding-top: 20px;

              @include mq-up(pc) {
                padding-top: 40px;
              }
            }

            &__icon {
              padding-right: 14px;
              font-family: $font-family-faq;
              font-weight: $light-font-weight;
              font-size: 28px;

              @include mq-up(pc) {
                padding-right: 32px;
                font-size: 36px;
              }
            }

            &__title {
              font-size: 15px;
              font-weight: $bold-font-weight;

              @include mq-up(pc) {
                font-size: 18px;
              }
            }
          }
        }

        &__icon {
          display: none;

          @include mq-up(pc) {
            display: block;
            width: 40px;
            height: 40px;
            margin-right: 40px;
            margin-top: 54px;
            position: relative;
          }

          &__side-line {
            width: 40px;
            height: 2px;
            background-color: $main-color;
            display: block;
            position: absolute;
            top: 15px;
            transform: rotate(90deg);
            transition-duration: 0.3s;

            &.is-open {
              transform: rotate(0);
            }
          }

          &__vertical-line {
            width: 40px;
            height: 2px;
            background-color: $main-color;
            display: block;
            position: absolute;
            top: 15px;

            &.is-open {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  //----------/FAQ ----------

  //----------blog ----------

  .main__blog {
    padding: 0 27px;

    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 0 0;
    }

    &__category-list {
      display: flex;
      flex-wrap: wrap;

      @include mq-up(m) {
      }

      &__item {
        margin-bottom: 20px;
        text-align: center;
        display: block;
        margin-right: 10px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: $bold-font-weight;
        opacity: 0.5;

        @include mq-up(pc) {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }

  //----------/blog ----------

  //---------- students-voice ----------

  .main__students-voice {
    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto;
    }

    &__menu-list {
      padding: 0 27px;
      text-align: center;
      font-size: 15px;
      font-weight: $bold-font-weight;

      @include mq-up(pc) {
        padding: 0;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
      }

      &__item {
        height: 40px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include border-box();
        transition-duration: 0.3s;

        &:hover {
          @include link-opacity(0.8);
        }

        @include mq-up(pc) {
          width: 31%;
          height: 180px;
        }

        &__icon {
          position: absolute;
          right: 12px;

          @include mq-up(pc) {
            width: 20px;
            display: block;
            right: 47%;
            bottom: 28px;
          }
        }
      }
    }

    &__list-container {
      padding: 0 27px;
      margin-top: 44px;

      @include mq-up(pc) {
        padding: 0;
        margin-top: 78px;
      }
    }
  }

  //---------- /students-voice ----------

  //---------- information ----------

  .main__information {
    padding: 0 27px;

    @include mq-up(pc) {
      padding: 0;
      @include pc-width();
    }

    &__list-container {
      &:first-child {
        border-top: 1px solid $main-color;
      }

      &__item {
        padding: 20px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $main-color;
        transition-duration: 0.3s;

        &:hover {
          background-color: $gray-back;
          transition-duration: 0.5s;
        }

        @include mq-up(pc) {
          padding: 40px 0;
        }

        &__date {
          margin-right: 24px;
          font-size: 14px;

          @include mq-up(pc) {
            margin-right: 52px;
          }
        }

        &__title {
          @include mq-up(m) {
            margin-left: 24px;
          }
          @include mq-up(pc) {
            margin-left: 52px;
          }
        }
      }
    }
  }

  //---------- /information ----------

  //---------- sitemap ----------

  .main__sitemap {
    padding: 0 27px;
    margin-bottom: 66px;

    @include mq-up(pc) {
      @include pc-width();
    }

    &__list-container {
      @include mq-up(m) {
        margin-bottom: 68px;
        display: flex;
        justify-content: space-between;
      }

      @include mq-up(pc) {
        margin-bottom: 100px;
      }

      &__split {
        @include mq-up(m) {
          width: 48%;
        }

        &__item {
          margin-bottom: 44px;
          font-weight: $bold-font-weight;

          @include mq-up(pc) {
            margin-bottom: 50px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &__main-link {
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $main-color;
            font-size: var(--middle-font-size);

            @include mq-up(pc) {
              font-size: 21px;
            }

            &__icon {
              margin-right: 10px;

              @include mq-up(pc) {
                margin-right: 15px;
              }
            }
          }

          &__sub-link {
            display: flex;
            margin-top: 14px;

            @include mq-up(pc) {
              margin-top: 20px;
            }

            &__icon {
              margin-right: 10px;

              @include mq-up(pc) {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }

  //---------- /sitemap ----------

  //---------- privacy ----------

  .main__privacy {
    padding: 0 20px;
    margin-bottom: 66px;

    @include mq-up(pc) {
      @include pc-width();
    }

    &__list-container {
     

      @include mq-up(pc) {
        margin: 0 86px;
      }

      &__item {
        margin-bottom: 44px;

        @include mq-up(pc) {
          margin-bottom: 64px;
        }

        &:last-child {
          @include mq-up(pc) {
            margin-bottom: 160px;
          }
        }

        &__title {
          padding-bottom: 10px;
          font-size: var(--middle-font-size);
          font-weight: $bold-font-weight;

          @include mq-up(pc) {
            padding-bottom: 28px;
            font-size: 20px;
          }
        }

        &__list {
          &:last-child {
            margin-bottom: 0;
          }

          &__item {
            margin-bottom: 38px;
          }
        }
      }
    }
  }

  //---------- /privacy ----------

  //---------- thanks ----------

  .main__thanks {
    margin-top: 50px;
    text-align: center;

    @include mq-up(pc) {
      margin-top: 80px;
    }

    &__top-icon {
      width: 40px;
      margin: 0 auto;
      padding: 50px 0 30px;
      display: block;

      @include mq-up(pc) {
        padding: 50px 0 30px;
      }
    }

    &--download {
      display: block;
      margin: 30px auto 20px;
      max-width: 250px;
      text-align: center;
      background: $white;
      color: #070a49;
      border: 2px solid #070a49;
      font-weight: 600;
      padding: 10px;
      border-radius: 40px;
      transition: .3s;
      transition-property: background-color, color;

      &:hover {
        background: #070a49;
        color: $white;
      }
    }

    &__title {
      padding-bottom: 44px;
      font-size: 20px;
      font-weight: $bold-font-weight;

      @include mq-up(pc) {
        padding-bottom: 60px;
        font-size: 32px;
      }
    }

    &__top-link {
      margin: 66px 0;
      display: block;
      color: $blue;

      @include mq-up(pc) {
        margin: 30px 0 200px;
      }
    }
  }

  //---------- /thanks ----------

  //---------- company-profile ----------

  .main__company-profile {
    @include mq-up(pc) {
      @include pc-width();
    }

    &__list-container {
      margin: 0 27px;

      @include mq-up(pc) {
        margin: 0 86px;
      }

      &:first-child {
        border-top: 1px solid rgba($color: $blue, $alpha: 0.2);
      }

      &__item {
        padding: 30px 0;
        border-bottom: solid 1px rgba($color: $blue, $alpha: 0.2);

        @include mq-up(m) {
          padding: 27px 0 14px;
          display: flex;
        }

        &__title {
          margin-bottom: 14px;

          @include mq-up(m) {
            width: 232px;
            font-size: 16px;
          }

          @include mq-up(pc) {
            width: 34%;
          }
        }

        &__text {
          display: block;
          font-size: var(--middle-font-size);
        }
      }
    }

    &__container {
      margin: 66px 0;

      @include mq-up(pc) {
        margin: 100px 0;
      }

      &__title {
        margin-left: 27px;
        @include line-title();

        @include mq-up(pc) {
          margin-left: 86px;
        }
      }

      &__top {
        margin-top: 55px;

        @include mq-up(pc) {
          margin-top: 80px;
        }

        &__copy {
          margin-left: 27px;
          font-size: 24px;
          font-weight: $bold-font-weight;

          @include mq-up(m) {
            display: none;
          }
        }

        &__img {
          width: 100%;
          margin: 20px 0;

          &.sp {
            display: block;
            @include mq-up(m) {
              display: none;
            }
          }

          &.pc {
            display: none;
            @include mq-up(m) {
              display: block;
            }
          }
        }

        &__name {
          margin-right: 27px;
          font-size: 18px;
          font-weight: $bold-font-weight;
          text-align: right;

          @include mq-up(m) {
            display: none;
          }

          &__sub-title {
            font-size: var(--base-font-size);
            margin-right: 20px;
          }
        }
      }

      &__list-container {
        margin-top: 44px;
        padding: 0 27px;

        @include mq-up(pc) {
          margin-top: 100px;
          margin-bottom: 160px;
          padding: 0 86px;
        }

        &__item {
          margin-bottom: 44px;
         

          @include mq-up(pc) {
            margin-bottom: 100px;
          }

          &__title {
            padding-bottom: 22px;
            font-size: var(--middle-font-size);
            font-weight: $bold-font-weight;

            @include mq-up(m) {
              font-size: 24px;
            }

            @include mq-up(pc) {
              padding-bottom: 32px;
              font-size: 32px;
            }
          }
        }
      }
    }
  }

  //---------- /company-profile ----------

  //---------- for business ----------

  .main__for-business {
    padding: 0 27px;
    margin-bottom: 66px;

    @include mq-up(pc) {
      @include pc-width(100px);
      margin: 0 auto 100px;
    }

    &__list-container {
      margin-bottom: 44px;
     

      @include mq-up(pc) {
        padding: 0 86px;
      }

      &__item {
        margin-bottom: 44px;

        @include mq-up(pc) {
          margin-bottom: 60px;
        }

        &__title {
          margin-bottom: 32px;
          @include line-title();

          @include mq-up(pc) {
            margin-bottom: 42px;
          }
        }

        &__text {
          @include mq-up(pc) {
            line-height: 42px;
          }
        }
      }
    }
  }

  //---------- /for business ----------

  //---------- contact ----------

  .main__contact {
    padding: 0 27px;

    @include mq-up(pc) {
      @include pc-width();
    }

    &__text {
      font-size: var(--minimum-font-size);
      line-height: 20px;

      @include mq-up(m) {
        text-align: center;
        font-size: 16px;
        line-height: 32px;
      }
    }

    &__line {
      margin: 66px 0;

      @include mq-up(pc) {
        margin: 80px 0;
      }
    }

    &__form-wrap {
      margin-bottom: 66px;

      @include mq-up(pc) {
        margin: 0 86px 100px;
      }

      &__item-wrap {
        width: 100%;
        margin-bottom: 20px;


        @include mq-up(pc) {
          margin-bottom: 20px;
        }

        &__item {
          width: 100%;
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          display: flex;
          gap: 10px 20px;

          &__radio-wrap {
            display: flex;
            align-items: center;

            &__radio {
              margin-right: 12px;
            }
          }

          &__select {
            width: 50%;
            background-color: $gray-back;
            border-radius: 10px;
            border: none;
            padding: 13px 27px;

            @include mq-up(pc) {
              width: 30%;
            }
          }

          &__input {
            padding: 13px 27px;
            border-radius: 10px;
            background-color: $gray-back;
            border: none;
            max-width: 100%;
            box-sizing: border-box;
          }

          &__textarea {
            height: 180px;
            width: 100%;
            padding: 13px 27px;
            border: none;
            border-radius: 10px;
            background-color: $gray-back;
            resize: none;

            @include mq-up(pc) {
              margin-bottom: 28px;
            }
          }
        }

        &__title {
          font-weight: $bold-font-weight;
        }
      }
    }
  }

  .contact-btn {
    display: block;
    margin-inline: auto;
    width: fit-content;
    border-radius: 50px;
  }

  //---------- /contact ----------

  //---------- school ----------

  .main__top-school {
    width: 100%;
    height: 124px;
    margin-top: 50px;
    background-color: $main-color;
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @include mq-up(m) {
      height: 180px;
    }

    @include mq-up(pc) {
      height: 280px;
      margin-top: 80px;
      margin-bottom: 50px;
    }

    &__wrap {
      margin-left: 27px;
      color: $white;

      @include mq-up(pc) {
        @include pc-width();
      }

      &__sub-title {
        font-size: var(--minimum-font-size);
        text-transform: uppercase;
        @include mq-up(pc) {
          font-size: 18px;
        }

        &::after {
          content: "";
          display: block;
          width: 17px;
          height: 1px;
          margin-top: 4px;
          background-color: $white;

          @include mq-up(pc) {
            width: 28px;
          }
        }
      }

      &__title {
        margin-top: 13px;
        font-size: 18px;
        font-weight: $bold-font-weight;

        @include mq-up(pc) {
          font-size: 40px;
        }
      }
    }
  }

  .main__school {
    &__list-container {
      margin-top: 55px;

      table {
        th, td {
          border: 1px solid #070a49;
          padding: 5px 10px;
        }

        th {
          background: #070a49;
          color: white;
        }
      }

      &__item {
        padding: 0 27px;
        margin-bottom: 55px;

        &:nth-child(2) {
          @include mq-up(pc) {
            margin-bottom: 48px;
          }
        }

        &:nth-child(4) {
          @include mq-up(pc) {
            padding-bottom: 100px !important;
          }
        }

        @include mq-up(pc) {
          padding: 0;
          margin-bottom: 80px;
        }

        &:nth-child(4) {
          padding: 55px 0;
          background-color: $back-color;
        }

        &:nth-child(5) {
          padding: 0;
          margin-bottom: 89px;
        }

        &__title {
          margin-bottom: 44px;
          @include line-title();
          position: relative;

          @include mq-up(pc) {
            @include pc-width(47px);
          }

          &__title-link {
            display: block;
            font-size: 13px;
            font-weight: $medium-font-weight;
            position: absolute;
            bottom: 0px;
            right: 0;
          }
        }

        &__text {
          margin-bottom: 44px;
         

          @include mq-up(pc) {
            @include pc-width();
          }
        }

        &__img-container {
          @include mq-up(pc) {
            @include pc-width();
            display: flex;
            justify-content: space-between;
          }

          &__img {
            margin-top: 20px;
            display: block;

            @include mq-up(pc) {
              width: 48%;
              margin-top: 40px;
            }
          }
        }

        &__list-container {
          @include mq-up(pc) {
            @include pc-width();
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          &__item {
            margin-bottom: 20px;
            cursor: pointer;
            transition-duration: 0.3s;

            &:hover {
              @include link-opacity();
            }

            @include mq-up(pc) {
              width: 48%;
              margin-bottom: 32px;
            }

            &__top {
              height: 146px;
              position: relative;
              display: flex;
              overflow: hidden;

              @include mq-up(pc) {
                height: 280px;
              }

              &__title-wrap {
                display: flex;
                align-items: center;
                width: 55%;
                color: $white;
                background-color: $main-color;

                &__wrap {
                  margin-left: 14%;

                  &__title {
                    font-size: var(--middle-font-size);
                    font-weight: $bold-font-weight;

                    @include mq-up(pc) {
                      font-size: 24px;
                    }
                  }

                  &__sub-title {
                    margin-top: 8px;
                    font-size: var(--minimum-font-size);
                    text-transform: uppercase;
                    @include mq-up(pc) {
                      margin-top: 20px;
                      font-size: 14px;
                    }
                  }
                }
              }

              &__img-wrap {
                width: 45%;
                display: flex;

                &__img {
                  width: 100%;
                  object-fit: cover;
                }
              }

              &__icon {
                width: 14px;
                display: block;
                position: absolute;
                bottom: 7px;
                right: 22%;
                transition-duration: 0.3s;

                &.is-open {
                  transform: rotate(180deg);
                  transition-duration: 0.5s;
                }

                @include mq-up(pc) {
                  width: 18px;
                  bottom: 18px;
                }
              }
            }

            &__profile-container {
              background-color: $back-color;

              &__item {
                padding: 20px 27px;

                &:first-child {
                  padding: 44px 27px 20px;
                }

                &:last-child {
                  padding: 20px 27px 44px;
                }
              }
            }
          }
        }

        &__student-voice {
          @include mq-up(pc) {
            @include pc-width();
          }
        }

        &__school-list {
          @include mq-up(pc) {
            @include pc-width();
          }
        }

        &__news-list {
          @include mq-up(pc) {
            @include pc-width();
          }
        }

        &__map {
          & iframe {
            width: 100%;
            height: 440px;
            display: block;
          }

          &__text {
            margin: 34px 0;
            text-align: center;
            line-height: 26px;

            @include mq-up(pc) {
              margin: 40px 0 60px;
            }
          }
        }
      }
    }
  }

  //---------- /school ----------

  //---------- students voice content ----------

  .main__students-voice-content {
    padding: 115px 27px 0;

    @include mq-up(pc) {
      @include pc-width();
      padding: 190px 0 0;
    }

    &__wrap {
      @include mq-up(pc) {
        //margin: 0 86px;
      }

      &__title {
        margin-bottom: 44px;
        font-size: 20px;
        font-weight: $bold-font-weight;
        line-height: 33px;

        @include mq-up(pc) {
          font-size: 32px;
          line-height: 42px;
        }
      }

      &__sub-title, h2, h3, h4, h5, h6 {
        margin: 44px 0 32px;
        font-size: 16px;
        font-weight: $bold-font-weight;

        @include mq-up(pc) {
          margin: 60px 0 30px;
          font-size: 24px;
        }
      }

      a {
        color: #5195de !important;
      }

      ul {
        li {
          padding-left: 1.3em;
          position: relative;

          &:before {
            content: '';
            left: 0;
            top: .7em;
            width: 10px;
            height: 10px;
            background: $main-color;
            position: absolute;
          }
        }
      }

      a {
        //color: $key-color;
        text-decoration: underline;
      }

      &__text, p {
        font-size: 13px;

        @include mq-up(pc) {
          font-size: 16px;
          line-height: 42px;
        }
      }

      &__img, img {
        width: 100%;
        margin: 44px 0;
        height: auto;
        display: block;

        @include mq-up(pc) {
          margin: 60px 0;
        }
      }
    }
  }

  //---------- /students voice content ----------

  //---------- blog content ----------

  .main__top-content-title {
    margin-top: 0;

    @include mq-up(pc) {
      width: 95%;
      max-width: 1200px;
      margin: 80px auto 0;
    }

    &__wrap {
      padding: 50px 27px 0;

      @include mq-up(pc) {
        padding: 0 0 0;
        //margin: 0 86px;
      }

      &__date {
        opacity: 0.6;
        line-height: 0;

        @include mq-up(pc) {
          font-size: 14px;
        }
      }

      &__title {
        margin-top: 30px;
        font-size: 20px;
        font-weight: $bold-font-weight;
        line-height: 33px;

        @include mq-up(pc) {
          font-size: 32px;
          line-height: 43px;
        }
      }

      &__category-flex {
        display: flex;
        flex-wrap: wrap;

        .main__top-content-title__wrap__category-wrap {
          margin-right: 10px;
        }
      }

      &__category-wrap {
        min-width: 135px;
        height: 24px;
        margin-top: 20px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px $main-color;
        border-radius: 14px;
      }
    }

    &__thumbnail-wrap {
      padding: 0 27px;

      @include mq-up(pc) {
        padding: 0;
      }

      &__img {
        width: 100%;
        margin-top: 44px;
        display: block;

        @include mq-up(pc) {
          margin-top: 80px;
        }
      }
    }
  }

  .main__blog-content-wrap {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .main__blog-content {

    margin-top: 44px;
    padding: 0 27px 0;

    @include mq-up(pc) {
      padding: 0;
      //margin: 80px 86px 0;
    }

    &__edit {
      @extend %edit;
      word-break: break-word;

      span {
        font-family: $font-family !important;
      }

      &__sub-title {
        margin: 44px 0 32px;
        font-size: 16px;
        font-weight: $bold-font-weight;

        @include mq-up(pc) {
          margin: 60px 0 30px;
          font-size: 24px;
        }
      }

      &__text {
        font-size: 13px;

        @include mq-up(pc) {
          font-size: 16px;
          line-height: 42px;
        }
      }

      &__img {
        width: 100%;
        margin: 44px 0;
        display: block;
        height: auto;

        @include mq-up(pc) {
          margin: 60px 0;
        }
      }

      // BLOCK
      @import "./blocks/edit";
      @import "./blocks/block";
    }
  }

  .content-end-line {
    margin: 0 27px;

    @include mq-up(pc) {
      @include pc-width();
    }
  }

  //---------- /blog content ----------

  .contents-link {
    margin: 56px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: $bold-font-weight;
    line-height: 0;

    @include mq-up(pc) {
      width: 372px;
      margin: 100px auto 160px;
    }

    .is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &__prev {
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      border-bottom: solid 2px $main-color;

      &__img {
        width: 16px;
        margin-right: 8px;
        display: block;
      }
    }

    &__list {
      &__img {
        width: 23px;
        display: block;
      }
    }

    &__next {
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid $main-color;

      &__img {
        width: 16px;
        margin-left: 8px;
        display: block;
      }
    }
  }

  .link-area {
    margin-top: 66px;

    @include mq-up(pc) {
      margin-top: 100px;
    }

    &__course {
      padding: 0 27px 66px;

      @include mq-up(pc) {
        padding: 0 0 66px;
      }

      &__list-container {
        display: flex;
        justify-content: space-between;

        @include mq-up(pc) {
          @include pc-width();
        }

        &__item {
          width: 48%;
          height: 156px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          @include mq-up(pc) {
            height: 180px;
          }

          &.main-c {
            border: solid 2px $main-color;
          }

          &.blue-c {
            border: solid 2px $blue;
          }

          &__title {
            font-size: 16px;
            font-weight: $bold-font-weight;

            @include mq-up(pc) {
              font-size: 24px;
            }

            &.blue-c {
              color: $blue;
            }
          }

          &__icon {
            position: absolute;
            bottom: 26px;
          }
        }
      }
    }
  }


  //---------- 404 ----------

  .main__404 {
    margin-top: 50px;
    text-align: center;

    @include mq-up(pc) {
      margin-top: 80px;
    }

    &__title {
      padding-top: 129px;
      font-size: 50px;
      font-weight: $medium-font-weight;

      @include mq-up(pc) {
        padding-top: 180px;
      }
    }

    &__sub-title {
      font-size: 16px;
    }

    &__text {
      padding-top: 40px;
      font-size: 13px;

      @include mq-up(pc) {
        font-size: 16px;
      }
    }

    &__top-link {
      margin: 66px 0;
      display: block;
      color: $blue;

      @include mq-up(pc) {
        margin: 30px 0 200px;
      }
    }
  }

  //


  %edit {


    .navbar {
      border: none;
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
      box-shadow: 0 1px 2px rgba(0, 0, 0, .3)
    }

    .navbar-brand {
      font-size: 24px
    }

    .navbar-inverse .navbar-form input[type=password], .navbar-inverse .navbar-form input[type=text] {
      color: #fff;
      -webkit-box-shadow: inset 0 -1px 0 #b2dbfb;
      box-shadow: inset 0 -1px 0 #b2dbfb
    }

    .navbar-inverse .navbar-form input[type=password]::-moz-placeholder, .navbar-inverse .navbar-form input[type=text]::-moz-placeholder {
      color: #b2dbfb;
      opacity: 1
    }

    .navbar-inverse .navbar-form input[type=password]:-ms-input-placeholder, .navbar-inverse .navbar-form input[type=text]:-ms-input-placeholder {
      color: #b2dbfb
    }

    .navbar-inverse .navbar-form input[type=password]::-webkit-input-placeholder, .navbar-inverse .navbar-form input[type=text]::-webkit-input-placeholder {
      color: #b2dbfb
    }

    .navbar-inverse .navbar-form input[type=password]:focus, .navbar-inverse .navbar-form input[type=text]:focus {
      -webkit-box-shadow: inset 0 -2px 0 #fff;
      box-shadow: inset 0 -2px 0 #fff
    }

    .btn-default {
      position: relative
    }

    .btn-default:focus {
      background-color: #fff
    }

    .btn-default:active:hover, .btn-default:hover {
      background-color: #f0f0f0
    }

    .btn-default:active {
      -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-default:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: -webkit-radial-gradient(circle, #444 10%, transparent 10.01%);
      background-image: radial-gradient(circle, #444 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-size: 1000% 1000%;
      background-position: 50%;
      opacity: 0;
      pointer-events: none;
      transition: background .5s, opacity 1s
    }

    .btn-danger:after, .btn-info:after, .btn-primary:after, .btn-success:after, .btn-warning:after {
      content: "";
      left: 0;
      background-repeat: no-repeat;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      background-position: 50%;
      pointer-events: none;
      background-image: -webkit-radial-gradient(circle, #fff 10%, transparent 10.01%)
    }

    .btn-default:active:after {
      background-size: 0 0;
      opacity: .2;
      transition: 0s
    }

    .btn-primary {
      position: relative
    }

    .btn-primary:focus {
      background-color: #2196f3
    }

    .btn-primary:active:hover, .btn-primary:hover {
      background-color: #0d87e9
    }

    .btn-primary:active {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-primary:active, .btn-success:active {
      -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-primary:after {
      position: absolute;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-size: 1000% 1000%;
      opacity: 0;
      transition: background .5s, opacity 1s
    }

    .btn-primary:active:after {
      background-size: 0 0;
      opacity: .2;
      transition: 0s
    }

    .btn-success {
      position: relative
    }

    .btn-success:focus {
      background-color: #4caf50
    }

    .btn-success:active:hover, .btn-success:hover {
      background-color: #439a46
    }

    .btn-success:active {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-success:after {
      position: absolute;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-size: 1000% 1000%;
      opacity: 0;
      transition: background .5s, opacity 1s
    }

    .btn-success:active:after {
      background-size: 0 0;
      opacity: .2;
      transition: 0s
    }

    .btn-info {
      position: relative
    }

    .btn-info:active:hover, .btn-info:focus, .btn-info:hover {
      background-color: #000
    }

    .btn-info:active {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-info:active, .btn-warning:active {
      -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-info:after {
      position: absolute;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-size: 1000% 1000%;
      opacity: 0;
      transition: background .5s, opacity 1s
    }

    .btn-info:active:after {
      background-size: 0 0;
      opacity: .2;
      transition: 0s
    }

    .btn-warning {
      position: relative
    }

    .btn-warning:focus {
      background-color: #ff9800
    }

    .btn-warning:active:hover, .btn-warning:hover {
      background-color: #e08600
    }

    .btn-warning:active {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-warning:after {
      position: absolute;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-size: 1000% 1000%;
      opacity: 0;
      transition: background .5s, opacity 1s
    }

    .btn-warning:active:after {
      background-size: 0 0;
      opacity: .2;
      transition: 0s
    }

    .btn-danger {
      position: relative
    }

    .btn-danger:focus {
      background-color: #e51c23
    }

    .btn-danger:active:hover, .btn-danger:hover {
      background-color: #cb171e
    }

    .btn-danger:active {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-danger:active, .btn-link:active {
      -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-danger:after {
      position: absolute;
      background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
      background-size: 1000% 1000%;
      opacity: 0;
      transition: background .5s, opacity 1s
    }

    .btn-danger:active:after {
      background-size: 0 0;
      opacity: .2;
      transition: 0s
    }

    .btn-link {
      position: relative
    }

    .btn-link:focus {
      background-color: #fff
    }

    .btn-link:active:hover, .btn-link:hover {
      background-color: #f0f0f0
    }

    .btn-link:active {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .4)
    }

    .btn-link:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: -webkit-radial-gradient(circle, #444 10%, transparent 10.01%);
      background-image: radial-gradient(circle, #444 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-size: 1000% 1000%;
      background-position: 50%;
      opacity: 0;
      pointer-events: none;
      transition: background .5s, opacity 1s
    }

    .btn-link:active:after {
      background-size: 0 0;
      opacity: .2;
      transition: 0s
    }

    .btn {
      padding: 5px 15px;
      display: inline-block;
      text-transform: uppercase;
      border: none;
      -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, .4);
      box-shadow: 1px 1px 4px rgba(0, 0, 0, .4);
      -webkit-transition: all .4s;
      -o-transition: all .4s;
      transition: all .4s
    }

    .btn-link {
      border-radius: 3px;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #444
    }

    .btn-link:focus, .btn-link:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #444;
      text-decoration: none
    }

    .btn-link .disabled:active:hover, .btn-link .disabled:hover, .btn-link[disabled]:active:hover, .btn-link[disabled]:hover, fieldset[disabled] .btn-link:active:hover, fieldset[disabled] .btn-link:hover {
      background-color: #fff;
      color: #444
    }

    .btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default {
      background-color: rgba(0, 0, 0, .1);
      color: rgba(0, 0, 0, .4);
      opacity: 1
    }

    .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
      background-color: rgba(0, 0, 0, .1)
    }

    .btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
      margin-left: 0
    }

    .btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
      margin-top: 0
    }

    a.btn--orange {
      color: #fff;
      background-color: #eb6100;
      border-bottom: 5px solid #b84c00;
    }

    a.btn--orange:hover {
      margin-top: 3px;
      color: #fff;
      background: #f56500;
      border-bottom: 2px solid #b84c00;
    }

    a.btn--shadow {
      -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
      box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
    }

    a.btn--shadow {
      -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
      box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
    }
  }

  .main_info {
    background: yellow;
    font-weight: bold;

    .inner {
      max-width: 1150px;
      margin: 0 auto;
      line-height: 1.6;
      padding: 10px 20px 10px 25px;
      position: relative;
      font-size: 90%;
      text-align: center;

      &:before {
        //content: '※';
        position: absolute;
        left: 5px;
      }

      @media screen and (max-width: 560px) {
        text-align: left;
        &:before {
          content: '※';
          left: 8px;
        }
      }

    }
  }

  .info-wrap {
    width: 88%;
    max-width: 1140px;
    margin: 0 auto;

    .lp-contents-wrapper__title {
      margin-bottom: 20px;
    }

    .info-contents {
      font-weight: 700;
      max-width: 600px;
      margin: 40px auto;
      text-align: center;
      line-height: 1.6;
      font-size: 1.1rem;
      @media screen and (max-width: 560px) {
        margin: 20px auto;
      }
    }

    .info-contents-sub {
      border: 2px solid var(--main-color);
      padding: 20px;
      max-width: 500px;
      margin: 0 auto;
    }

    .caution {
      padding: 0;
    }
  }


  //---------- /404 ----------


  //loading

  .loader-wrap {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    left: 0;
    top: 0;
    z-index: 9999;
    background: rgba(#000, .6);

    &.js-active {
      opacity: 1;
      visibility: visible;
    }

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }

    .loader {
      margin: 40px auto;
      font-size: 7px;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .breadcrumbWrap{
    margin: 0 20px 27px;
    overflow: auto;
    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto 50px;
    }

    &:has(.is-detail) {
      margin: 100px 20px 0;
      @include mq-up(pc) {
        margin: 150px auto 0;
        max-width: 1200px;
      }
    }

    .is-voice {
      margin: 0 0 27px;
      @include mq-up(pc) {
        max-width: 1200px;
        width: 100%;
        //margin: 0 0 50px;
      }
    }
  }
  .breadcrumb {
    display: flex;
    padding: 20px 0;
    @include mq-up(pc) {
      flex-wrap: wrap;
    }

    li {
      margin-right: 40px;
      font-size: 90%;
      white-space:nowrap;
    }

    &__link {
      text-decoration: underline;
      position: relative;
      white-space:nowrap;
      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        display: block;
        transform: translateY(-50%) rotate(45deg);
        top: 50%;
        right: -23px;
        border-top: #070a49 solid 2px;
        border-right: #070a49 solid 2px;
      }
    }
  }

  .info-link-text {
    text-decoration: underline;
    color: #1989ff;
  }


  //---------- /add Price ----------

  .sub-price-aria {
    padding-bottom: 100px;

    .price-img {
      margin-bottom: 80px;
    }

    .main__es-page__list-container__item__title {
      width: 100%;
      margin: 0 auto 40px;
    }

    .price-caution {
      font-size: 100%;
      font-weight: 600;
      position: relative;
      padding-left: 1.3em;
      max-width: 1000px;
      margin: 0 auto;

      &:before {
        content: "※";
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .price-flex-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 60px auto 0;
      position: relative;
      max-width: 900px;

      .plus {
        width: 60px;
        height: 60px;
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;

        &:before, &:after {
          content: '';
          width: 100%;
          height: 15px;
          background: #575757;
          left: 50%;
          top: 50%;
          position: absolute;

        }

        &:before {
          transform: translate(-50%, -50%);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(90deg);
        }

        @include mq-down(m) {
          top: 260px;
        }
      }
    }

    .price-flex-item {
      width: calc(50% - 20px);
      text-align: center;
      color: #1d1365;
      max-width: 380px;
      @include mq-down(m) {
        width: 100%;
        max-width: 360px;
        margin: 0 auto;
        &.is-first {
          margin-bottom: 150px;
        }

      }

      h4 {
        color: white;
        font-size: 240%;
        line-height: 1.5;
        font-weight: bold;
        box-sizing: border-box;
        letter-spacing: 4px;
        margin-bottom: 30px;

        span {
          background: #1d1365;
          padding: 15px 30px;
        }
      }

      .price {
        line-height: 1.4;
        font-size: 450%;
        letter-spacing: 4px;
        font-weight: bold;
        margin-bottom: 10px;

        .border-span {
          border-bottom: 3px solid #1d1365;
        }

        .price-en {
          font-size: 40%;
        }
      }

      .price-detail {
        font-size: 110%;
        font-weight: bold;
        text-align: left;
      }

      .price-list-caution {
        line-height: 1.6;
        text-align: left;
        font-weight: bold;
        font-size: 60%;

        li:before {
          content: '※';
        }
      }
    }
  }

  .p-gym {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 27px 100px;

    @include mq-up(pc) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto;
    }

    .breadcrumb {
      margin-left: 0;
    }

    .gym-column {
      width: calc(100% - 300px - 40px);
      @include mq-down(m) {
        width: 100%;
      }

      &__ttl {
        font-size: 180%;
        font-weight: bold;
        line-height: 1.8;
        margin-bottom: 40px;
        @include mq-down(m) {
          font-size: 140%;
          margin-bottom: 20px;
        }
      }

      &__caution {
        font-size: 90%;
      }

      &__list {
        margin-top: 40px;

        &__ttl {
          font-size: 120%;
          font-weight: bold;
          letter-spacing: 1px;
          padding: 40px 0;
          cursor: pointer;
          position: relative;

          .toggle {
            display: block;
            width: 25px;
            height: 25px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 20px;

            &:before, &:after {
              content: '';
              width: 100%;
              height: 2px;
              position: absolute;
              background: $main-color;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
              display: block;
            }

            &:after {
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }

          &.js-active .toggle {
            &:after {
              content: none;
            }
          }
        }

        &__detail {
          display: none;
          flex-wrap: wrap;
          padding-bottom: 60px;
          gap: 10px;
        }

        &__ttl.js-active + .gym-column__list__detail {
          display: flex;
        }

        &__gym {
          background: #F4F4F4;
          padding: 2px 10px;

          a {
            text-decoration: underline;
          }

          .link {
            &:after {
              content: url(../images/link.svg);
              width: 12px;
              height: 12px;
              display: inline-block;
              margin-left: 8px;

            }

          }
        }

        &__item {
          border-bottom: 2px solid $main-color;

          &:first-child {
            border-top: 2px solid $main-color;
          }
        }
      }

      &__edit {
        &__map {
          margin-bottom: 40px;

          iframe {
            width: 100%;
            max-height: 300px;
          }
        }

        &__access {
          border-top: 2px solid $main-color;
          display: flex;
          flex-wrap: wrap;
          margin-top: 40px;
          margin-bottom: 20px;

          dt {
            width: 120px;
            font-weight: bold;
          }

          dd {
            width: calc(100% - 120px);
          }

          dt, dd {
            border-bottom: 2px solid $main-color;
            padding: 15px 0;
          }
        }
      }

    }

    .sub-column {
      width: 300px;
      @include mq-down(m) {
        width: 100%;
        margin-top: 60px;
      }

      &__dl {
        margin-bottom: 40px;
      }

      &__link {

      }

      &__ttl {
        background: $main-color;
        color: #fff;
        font-weight: bold;
        padding: 8px 15px;
        font-size: 110%;
        position: relative;

      }

      &__item {
        margin-top: 10px;
      }

      &__link {
        width: 100%;
        background: #EFF0F3;
        display: flex;
        line-height: 1.5;
        font-size: 90%;
        overflow: hidden;

        &:hover {
          opacity: .8;
        }

        &__img {
          width: 130px;
          min-height: 130px;
          height: 100%;
          object-fit: cover;
        }

        &__detail {
          padding: 10px;
          width: calc(100% - 130px);
        }

        &__category {
          margin-top: 10px;
          font-size: 90%;
          padding-top: 10px;
          border-top: 1px solid $main-color;
        }

        &__categoryItem {
          background: #fff;
          font-size: 80%;
          display: inline-block;
          padding: 3px 5px;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }

      &__linkGym {
        border-bottom: 1px solid $main-color;
        padding: 10px 15px;
        line-height: 1.5;
        display: block;

        &:hover {
          opacity: .8;
        }
      }


    }


  }

  .main__blog-content__writer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    border-bottom: 1px solid;
    padding: 20px 0;
    &:nth-child(1){
      border-top: 1px solid;
      margin-top: 50px;
    }
    @include mq-down(m) {
      gap: 15px;
    }

    dt {
      font-weight: bold;
    }

    &--img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      @include mq-down(m) {
        width: 20%;
        height: auto;
        aspect-ratio: 1 / 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--detail {
      width: calc(100% - 150px - 30px);
      line-height: 1.2;
      font-size: 14px;

      p {
        line-height: 1.5 !important;
      }

      @include mq-down(m) {
        width: calc(100% - 20% - 15px);
      }
    }

    &--nameWrap {
      display: flex;
      gap: 10px;
      line-height: 1.7;
      margin-bottom: 10px;
    }

    &--span {
      background: $main-color;
      color: $white;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 10px 0px;
    }

    &--name {
      font-weight: bold;
    }

    &--job {
      font-size: 12px;
      color: #797979;
      line-height: 1.7;
      margin-bottom: 5px;
    }

    &--article {
      @extend %edit;
      line-height: 1.7;
      margin-top: 5px;

      span {
        text-decoration-color: $sub-color !important;
        text-decoration-thickness: 3px !important;
      }

      a {
        color: #5195de;
        text-decoration-color: #5195de;
      }

      &__sub-title, h2, h3, h4, h5, h6 {
        margin: 44px 0 32px;
        font-size: 16px;
        font-weight: $bold-font-weight;

        @include mq-up(pc) {
          margin: 60px 0 30px;
          font-size: 24px;
        }
      }

      table {
        th, td {
          border: 1px solid #070a49;
          padding: 5px 10px;
        }

        th {
          background: #070a49;
          color: white;
        }
      }

      ul {
        li {
          padding-left: 1.3em;
          position: relative;

          &:before {
            content: '';
            left: 0;
            top: .7em;
            width: 10px;
            height: 10px;
            background: $main-color;
            position: absolute;
          }
        }
      }

      ol {
        counter-reset: section;

        li {
          padding-left: 1.5em;
          position: relative;

          &:before {
            counter-increment: section;
            content: counter(section) ".";
            left: 0;
            top: 0;
            position: absolute;
          }
        }
      }

      a {
        //color: $key-color;
        text-decoration: underline;
        font-size: 15px;
        @include mq-down(m) {
          font-size: 13px;
        }
      }

      strong {
        font-weight: bold;
      }

      &__text, p {
        font-size: 15px;
        @include mq-down(m) {
          font-size: 13px;
        }
      }

      &__img, img {
        width: 100%;
        margin: 44px 0;
        display: block;
        height: auto;

        @include mq-up(pc) {
          margin: 60px 0;
        }
      }
    }
  }

  .wp-embedded-content {
    @include mq-down(m) {
      width: 100%;

    }
  }

  .l-cptBnr {
    display: none;
    @include mq-down(m) {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      &__padding {
        height: 60px;
      }
      &__item {
        flex: 1;
        padding: 10px 20px;
        height: 60px;
        display: flex;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        line-height: 1.2;
        box-sizing: border-box;
        background: black;
        color: white;
        text-align: center;
        font-size: 16px;
      }

    }
  }

  .main__columnWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    .main__columnLeft {
      width: calc(100% - 290px);
      @include mq-down(pc) {
        width: 100%;
      }
    }

    .main__columnRight {
      width: 250px;
      box-sizing: border-box;
      background: #eff0f3;
      position: sticky;
      padding-bottom: 10px;
      top: 120px;
      @include mq-down(pc) {
        display: none;
      }

      &--ttl {
        font-weight: bold;
        margin-bottom: 20px;
        padding: 15px 20px;
        color: white;
        font-size: 110%;
        background: $main-color;
      }

      &--item {
        display: block;
        padding: 8px 0;
        margin: 0 20px;
        padding-left: 1.3em;
        position: relative;
        border-bottom: 1px solid $main-color;
        text-decoration: none;
        color: $main-color !important;
        line-height: 1.6;

        &:last-child {
          border-bottom: none;
        }

        span {
          position: absolute;
          left: 0;
        }
      }
    }
  }

  .required-text {
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    background-color: $main-color;
    padding: 3px 6px;
    margin-left: 10px;
    border-radius: 8px;
  }

  .mw_wp_form .horizontal-item + .horizontal-item {
    margin-left: 0 !important;
  }

  .l-map {

    iframe {
      width: 100%;
      @include mq-down(m) {
        height: 300px;
      }
    }

  }

  .contact-guidance {
    width: 100%;
    padding: 40px 0;
    background-color: $main-color;
    @include mq-down(pc) {
      padding: 40px 10px;
      box-sizing: border-box;
    }
  }

  .contact-guidance.is-white {
    background-color: white !important;
  }

  .contact-guidance.is-none-space {
    padding-top: 0;
    padding-bottom: 0;
  }

  .contact-guidance__title {
    font-size: var(--lp-sub-font);
    margin: 0 auto 30px !important;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  .is-white .contact-guidance__title {
    color: $main-color;
  }

  .contact-guidance__text {
    width: var(--lp-width);
    margin: 0 auto;
    padding: var(--lp-small-space) 0;
    line-height: 1.7 !important;
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
  }

  .is-white .contact-guidance__text {
    color: $main-color;
  }


  .contact-guidance__button__text {
    max-width: 355px;
    width: 100%;
    margin: 0 auto;
    font-size: var(--lp-sub-font);
    font-weight: 700;
    color: $main-color;
    text-align: center;
    padding: 16px 0;
    background-color: $sub-color;
    border-radius: 25px;
  }

  .total-cta {
    max-width: 370px;
    display: block;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  @media screen and (min-width: 1080px) {
    .contact-guidance__button__text {
      max-width: 590px;
      width: 100%;
      border-radius: 50px;
    }
  }

  .support__anker {
    margin: 0 27px;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    font-size: 110%;
    color: $main-color;
    @media screen and (min-width: 1080px) {
      width: 77%;
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    &__link {
      box-sizing: border-box;
      padding: 10px 60px 10px 30px;
      border: solid 2px $main-color;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        transform: translateY(-50%) rotate(45deg);
        top: 50%;
        right: 20px;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid $main-color;
        border-right: 2px solid $main-color;
      }
    }
  }

  .main__es-page__list-container__item {
    margin-top: -100px;
    padding-top: 100px;
  }

  .main__list {
    margin-top: 20px;

    a {
      text-decoration: underline;
      line-height: 1.6;
      color: #0c6ca0;
    }
  ;
  }

  .main__cv {
    padding: 40px 0;
    background: $main-color;
    margin-bottom: 60px;
    @include mq-down(m) {
      margin: 0 -27px 60px;
      padding: 40px 10px;
    }
  }

  .l-thanks {
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    //
    //&__left{
    //  width: 50%;
    //}
    //&__right{
    //  width: 50%;
    //}
    &__inner {
      position: relative;
      margin-top: 40px;

      &--img {
        max-width: 100%;
        margin: 0 auto;
        height: auto !important;
      }

      &--btn {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        margin: 0 auto;
        display: block;
        width: fit-content;
        z-index: 1;

        &:hover {
          opacity: .6;
        }

      }

      &--btnImg {
        max-width: 450px;
        display: block;
        @media screen and (max-width: 500px) {
          max-width: inherit;
          width: 70vw;
        }
      }

      .btn1 {
        top: 805px;
        @media screen and (max-width: 500px) {
          top: 136vw;
        }
      }

      .btn2 {
        bottom: 60px;

        @media screen and (max-width: 500px) {
          bottom: 10vw;
        }
      }
    }
  }

  .scroll__swiperWrap {
    width: 100vw;
    //overflow: auto;
    margin: 40px -27px 40px;
    overflow: hidden;


    @media screen and (min-width: 1080px) {
      position: absolute;
      left: 0;
      visibility: hidden;
    }
  }

  .scroll__swiper {
    display: flex;
    flex-wrap: wrap !important;
    flex-flow: column;
    height: 168px;
    //animation: slide 20s linear infinite;
    li {
      text-align: center;
      background: $white;
      height: 84px;
    }

    img {
      margin: 0 auto;
      height: 84px;
    }
  }

  @keyframes slide {
    0% {
      transform: translateX(0%);
    }
    to {
      transform: translateX(calc(-50%));
    }
  }

  .c-edit {
    @import "./blocks/edit";
  }
}

.main {
  position: relative;

  &__header-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    font-size: var(--base-font-size-eng);
    font-weight: $medium-font-weight;

    @include mq-up(pc) {
    }

    &__img-wrap {
      &__img {
        width: 128px;
        display: block;
        padding: 0 0 0 16px;

        @include mq-up(pc) {
          width: 180px;
          padding: 0 0 0 48px;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;

      &__tell {
        margin-right: 16px;
        color: $main-color;
        @include mq-up(pc) {
          margin-right: 22px;

        }
      }

      &__button {
        display: none;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 40px;
        margin-right: 22px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: $bold-font-weight;
        background-color: $key-color;

        &:hover {
          box-shadow: 2px 2px 20px rgba($color: $main-color, $alpha: 0.5);
          @include link-opacity();
        }

        @include mq-up(pc) {
          display: flex;
        }

        @include mq-up(xl) {
          margin-right: 48px;
        }
      }

      &__nav {
        text-align: right;
        font-size: 14px;
        font-weight: $bold-font-weight;
        margin-right: 30px;
        z-index: 1000;
        &__menu-list {
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 1px;
            height: 15px;
            background-color: $main-color;
            position: absolute;
            top: 6px;
            right: 0;
          }

          &__item {
            margin: 0 15px;
            &:last-child {
              margin: 0 30px 0 15px;
            }
          }
        }
      }

      //----- hamburger-menu -----

      &__menu {
        width: 30px;

        margin-right: 16px;
        cursor: pointer;
        transition: 1s;
        position: relative;
        margin-top: 10px;
        &__text{
          text-align: center;
          font-size: 10px;
        }
        &__toggle{
          height: 12px;
          position: relative;
        }
        @include mq-up(pc) {
          margin-right: 48px;
        }

        @include mq-up(xl) {
          display: none;
        }


        #line1 {
          width: 100%;
          height: 3px;
          transform: translate(-50%,-50%) rotate(0);
          transform-origin: center;
          position: absolute;
          top: calc(50% - 5px);
          left: 50%;
          transition: 0.7s;
          background-color: $main-color;
          &.line_1 {
            transform: translate(-50%,-50%) rotate(-20deg);
            top: 50%;
          }

        }

        #line3 {
          width: 100%;
          height: 3px;
          position: absolute;
          transform: translate(-50%,-50%) rotate(0);
          transform-origin: center;
          top: calc(50% + 5px);
          left: 50%;
          transition: 0.7s;
          background-color: $main-color;
          &.line_3 {
            transform: translate(-50%,-50%) rotate(20deg);
            top: 50%;
          }
        }
      }

      //----- /hamburger-menu -----
    }

    &__bnr{
      background: #fff;
      img{
        display: block;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
      }
    }
    &__bnrTxt{
      text-align: center;
      color: #fff;
      font-size: 25px;
      font-weight: bold;
      margin: 20px 0;
    }
  }

  &__nav {
    position: absolute;
    top: 50px;
    right: -100%;
    width: 100%;
    background-color: $main-color;
    height: calc(100svh - 50px);
    transition: 0.3s;
    padding-bottom: 60px;
    box-sizing: border-box;
    overflow: auto;

    &__menu-list {
      padding: 44px 27px;

      &__item {
        padding: 12px 0;
        font-size: var(--middle-font-size);
        border-bottom: solid 1px rgba($color: $white, $alpha: 0.5);
        display: block;
        color: $white !important;

        &:first-child {
          border-top: solid 1px rgba($color: $white, $alpha: 0.5);
        }
      }
    }
  }

  &__top-title {
    margin-top: 50px;

    @include mq-up(pc) {
      @include pc-width(80px);
      margin-bottom: 50px;
    }

    &__wrap {
      padding: 44px 20px 0;
      margin-bottom: 25px;
      text-align: center;
      color: $white;

      @include mq-up(pc) {
        padding: 100px 0 0;
      }

      &__title {
        width: 100%;
        padding: 10px 0;
        background-color: $main-color;
        font-size: 15px;
        font-weight: $bold-font-weight;

        @include mq-up(pc) {
          font-size: 20px;
          padding: 16px 0;
        }
      }
    }
  }

  //----- students voice content -----

  &__top-thumbnail {
    margin-top: 50px;

    @include mq-up(pc) {
      margin-top: 80px;
    }

    &__content {
      position: relative;

      @include mq-up(pc) {
        @include pc-width();
        padding-top: 160px;
      }

      &__img-wrap {
        width: 100%;

        &__img {
          width: 100%;
          height: auto;
          object-fit: cover;

          @include mq-up(pc) {
            height: auto;
          }
        }
      }

      &__info-area {
        width: 188px;
        height: 118px;
        padding: 24px 32px;
        background-color: $main-color;
        color: $white;
        position: absolute;
        bottom: -83px;
        left: 27px;

        @include mq-up(pc) {
          width: 288px;
          height: 180px;
          padding: 30px 46px;
          left: 40px;
          bottom: -120px;
        }

        &__info {
          &__school-data {
            display: flex;
            font-size: var(--minimum-font-size);
            font-weight: $bold-font-weight;
            position: relative;

            @include mq-up(pc) {
              font-size: 16px;
            }

            &::after {
              content: "";
              width: 25px;
              height: 2px;
              display: block;
              background-color: $white;
              position: absolute;
              top: 32px;

              @include mq-up(pc) {
                width: 36px;
                top: 46px;
              }
            }

            &__school {
              padding-right: 10px;

              @include mq-up(pc) {
                padding-right: 20px;
              }
            }
          }

          &__name {
            padding: 18px 0 14px;
            font-size: 20px;
            font-weight: $bold-font-weight;

            @include mq-up(pc) {
              padding: 40px 0 26px;
              font-size: 28px;
            }
          }

          &__date {
            font-size: var(--minimum-font-size);

            @include mq-up(pc) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  //----- students voice content -----

  &__top {
    margin-top: 50px;

    @include mq-up(pc) {
      margin-top: 80px;
    }

    &__img {
      width: 100%;
      height: auto;
    }

    &__head-img {
      width: 100%;
      height: 150px;
      margin-bottom: 25px;
      background-size: cover;
      color: $white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq-up(m) {
        height: 280px;
      }

      @include mq-up(pc) {
        height: 350px;
        margin-bottom: 50px;
      }

      &__title-container {
        &__sub-title {
          position: relative;
          font-size: var(--minimum-font-size);

          @include mq-up(m) {
            font-size: 18px;
          }

          &::after {
            content: "";
            display: block;
            width: 17px;
            height: 1px;
            margin: 2px auto;
            background-color: $white;

            @include mq-up(m) {
              width: 28px;
              height: 2px;
              margin: 20px auto;
            }
          }
        }

        &__title {
          margin-top: 12px;
          font-size: 18px;
          font-weight: $bold-font-weight;

          @include mq-up(m) {
            margin-top: 30px;
            font-size: 40px;
            line-height: 70px;
          }
        }
      }
    }
  }

  &__common-area {
    padding: 60px 27px;
    text-align: center;
    background: url(../images/img_06.jpg);
    background-size: cover;
    background-position: center;
    color: $white;

    @include mq-up(pc) {
      padding: 68px 0;
    }

    &__title {
      font-size: 19px;
      font-weight: $bold-font-weight;
      line-height: 36px;

      @include mq-up(pc) {
        font-size: 32px;
        line-height: 50px;
      }
    }

    &__text {
      font-size: var(--base-font-size-eng);
      line-height: 24px;
      padding: 32px 0;

      @include mq-up(pc) {
        padding: 32px 0 44px;
        line-height: 32px;
      }
    }

    &__performance {
      margin-bottom: 44px;

      @include mq-up(pc) {
        width: 860px;
        margin: 0 auto 44px;
        display: flex;
        justify-content: space-around;
      }

      &__item {
        height: 109px;
        margin-bottom: 22px;
        border: 2px solid $white;
        font-size: var(--title-font-size);
        font-weight: $bold-font-weight;
        line-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq-up(pc) {
          width: 230px;
          height: 130px;
          font-size: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__common-students-voice {
    padding: 55px 27px;

    @include mq-up(pc) {
      padding: 68px 0;
    }

    &__text {
      font-size: var(--middle-font-size);
      margin-bottom: 30px;
      font-weight: $bold-font-weight;
      text-align: center;

      @include mq-up(pc) {
        font-size: 20px;
        margin-bottom: 48px;
      }
    }

    &__link-area {
      width: 100%;
      max-width: 1200px;
      height: 150px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../dist/images/img_10.jpg);
      background-size: cover;
      background-position: center center;
      transition-duration: 0.3s;

      &:hover {
        @include link-opacity();
      }

      @include mq-up(pc) {
        width: 77%;
        height: 265px;
      }

      &__title {
        width: 138px;
        height: 22px;
        padding: 12px 0;
        border: solid 3px $white;
        color: $white;
        font-weight: $bold-font-weight;
        font-size: var(--middle-font-size);
        text-align: center;

        @include mq-up(pc) {
          width: 277px;
          font-size: 18px;
          padding: 40px 0 44px;
          border: solid 5px $white;
        }
      }
    }
  }

  //---------- main container ----------

  &__container {
    padding: 57px 27px;

    @include mq-up(pc) {
      padding: 100px 0;
    }

    &:nth-child(4) {
      padding: 57px 27px;
      background-color: $back-color;
      @include mq-up(pc) {
        padding: 100px 0;
      }
    }

    &:nth-child(5) {
      padding: 57px 0;

      @include mq-up(pc) {
        padding: 100px 0;
      }
    }

    &:nth-child(6) {
      background-color: $back-color;
    }

    &:nth-child(7) {
      padding: 0 0 57px 0;

      @include mq-up(pc) {
        padding: 0 0 100px 0;
      }
    }

    &:nth-child(8) {
      padding: 57px 27px;
      background-color: $back-color;

      @include mq-up(pc) {
        padding: 100px 0;
      }
    }


    &__inner {
      @include mq-up(pc) {
        width: 77%;
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    &__title-area {
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;

      line-height: 1.4;
      position: relative;

      @include mq-up(pc) {
        width: 77%;
        max-width: 1200px;
        margin: 0 auto;
        padding-bottom: 60px;
      }

      &__title {
        padding-left: 10px;
        font-size: var(--title-font-size);
        font-weight: $bold-font-weight;
        position: relative;
        @include mq-up(pc) {
          padding-left: 32px;
        }

        &::before {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background-color: $main-color;

          @include mq-up(pc) {
            width: 3px;
          }
        }

        &__sub-title {
          padding-left: 10px;
          font-size: var(--base-font-size-eng);
          font-weight: $medium-font-weight;

          @include mq-up(pc) {
            padding-left: 18px;
          }
        }
      }

      &__title-link {
        text-align: right;
        font-size: var(--base-font-size);
        font-weight: $medium-font-weight;
      }
    }

    &__vertical-title-area {
      font-weight: $bold-font-weight;
      text-align: center;

      &__title {
        position: relative;
        font-size: var(--title-font-size);
        margin-top: 18px;
        @include mq-down(m){
          font-size: 1.8rem;
        }
        &::after {
          content: "";
          display: block;
          margin: 33px auto;
          width: 40px;
          height: 2px;
          background-color: $main-color;
        }
      }
    }

    //----- contents -----

    &__contents {
      position: relative;

      @include mq-up(pc) {
        width: 77%;
        max-width: 1200px;
        margin: 0 auto;
      }

      &__flex {
        @include mq-up(m) {
          margin-bottom: 42px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        }
      }

      &__copy-wrap {

        @include mq-up(m) {
          margin-bottom: 28px;
          width: 48%;
        }

        &__copy {
          font-weight: $bold-font-weight;
          font-size: 21px;
          line-height: 1.8;
          margin-bottom: 30px;
          @include mq-up(pc) {
            font-size: 30px;
            //line-height: 64px;
          }
        }

        &__img {
          margin-top: 20px;
          @include mq-up(pc) {
            margin-top: 0px;
            width: 48%;
          }

        }
      }

      &__text {
      }

      &__performance-list {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        border: $gray solid 2px;
        border-radius: 14px;

        @include mq-up(m) {
          height: 240px;
          justify-content: space-around;
          align-items: center;
        }

        @include mq-up(pc) {
          height: 312px;
        }

        &::before {
          content: "";
          width: 2px;
          height: 90%;
          background-color: $gray;
          display: block;
          position: absolute;
          top: 5%;
          left: 50%;

          @include mq-up(m) {
            display: none;
          }
        }

        &::after {
          content: "";
          width: 90%;
          height: 2px;
          background-color: $gray;
          position: absolute;
          top: 50%;
          left: 5%;

          @include mq-up(m) {
            display: none;
          }
        }

        &__item {
          width: 50%;
          padding: 30px 0;
          text-align: center;

          @include mq-up(m) {
            width: 25%;

            &:nth-child(2) {
              &::before {
                content: "";
                width: 1px;
                height: 208px;
                display: block;
                background-color: $gray;
                position: absolute;
                top: 17px;
              }

              &::after {
                content: "";
                width: 1px;
                height: 208px;
                display: block;
                background-color: $gray;
                position: absolute;
                right: 50%;
                top: 17px;
              }
            }

            &:nth-child(3) {
              &::after {
                content: "";
                width: 1px;
                height: 208px;
                display: block;
                background-color: $gray;
                position: absolute;
                top: 17px;
                right: 25%;
              }
            }
          }

          @include mq-up(pc) {
            width: 25%;

            &:nth-child(2) {
              &::before {
                height: 280px;
              }

              &::after {
                height: 280px;
              }
            }

            &:nth-child(3) {
              &::after {
                height: 280px;
              }
            }
          }

          &__title {
            margin-top: 14px;
            font-weight: $bold-font-weight;

            @include mq-up(pc) {
              margin-top: 30px;
              margin-bottom: 14px;
            }
          }

          &__value {
            font-size: 25px;

            @include mq-up(pc) {
              font-size: 40px;
            }
          }

          &__img {
            width: 76px;
            margin: 0 auto !important;
            @include mq-up(pc) {
              width: 120px;
            }
          }
        }

        &__annotation {
          margin-top: 14px;

          @include mq-up(pc) {
            margin-top: 42px;
          }
        }
      }

      &__card-link {
        padding-bottom: 34px;
        //margin-top: 54px;
        color: $white;

        @include mq-up(m) {
          width: 100%;
          padding-bottom: 0;
          //margin-top: 100px;
          display: flex;
          flex-direction: row-reverse;
        }

        &__img {
          width: 100%;
          display: block;

          @include mq-up(m) {
            width: 55%;
          }
        }

        &__text-area {
          padding-bottom: 32px;
          background-color: $main-color;

          @include mq-up(m) {
            width: 45%;
            padding-bottom: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }

          &__wrap {
            &__text {
              padding: 32px 30px;

              @include mq-up(m) {
                padding: 0 40px 47px 40px;
              }
            }
          }
        }
      }

      &__part-list {
        @include mq-up(m) {
          margin-top: 20px;
        }

        &__item {
          margin-top: 44px;

          @include mq-up(m) {
            margin: 0 27px 80px 27px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          @include mq-up(pc) {
            margin: 0 0 80px 0;
          }

          &:nth-child(even) {
            @include mq-up(m) {
              flex-direction: row-reverse;
            }
          }

          &:last-child {
            @include mq-up(m) {
              margin: 0 27px 0 27px;
            }

            @include mq-up(pc) {
              margin: 0;
            }
          }

          &__text-wrap {
            padding-left: 25px;
            margin-bottom: 20px;
            @include mq-down(m) {
              padding-left: 0;
            }
            @include mq-up(m) {
              width: 45%;
              padding-left: 0;
            }

            &__number {
              margin-left: 27px;
              display: inline-block;
              font-size: 33px;

              @include mq-up(m) {
                margin-left: 0;
              }

              @include mq-up(pc) {
                font-size: 65px;
                padding-bottom: 16px;
              }
            }

            &__title {
              padding-left: 27px;
              margin-top: 32px;
              font-size: var(--title-font-size);
              font-weight: $bold-font-weight;

              @include mq-up(m) {
                padding-left: 0;
                margin-top: 47px;
              }
            }

            &__text {
              margin: 32px 27px;

              @include mq-up(m) {
                margin: 32px 0 0 0;
              }
            }
          }

          &__img-wrap {
            @include mq-up(m) {
              display: block;
              width: 50%;
            }

            &__img {
              width: 100%;
              display: block;

              @include mq-up(m) {
                width: 100%;
              }
            }
          }
        }
      }

      //----- student -----

      &__graph-list {
        margin-bottom: 44px;

        @include mq-up(pc) {
          margin-bottom: 80px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        &__item {
          //height: 597px;
          margin: 0 9px;
          border: $gray solid 2px;
          border-radius: 14px;
          text-align: center;
          padding-bottom: 20px;
          min-width: 300px;
          @include mq-up(pc) {
            width: 48%;
            //height: 680px;
            margin: 0;
            margin-bottom: 42px;
          }

          &:first-child {
            margin-left: 27px;

            @include mq-up(pc) {
              margin-left: 0;

            }
          }

          &:last-child {
            margin-right: 27px;

            @include mq-up(pc) {
              margin-right: 0;
            }
          }

          &__title {
            padding: 38px 0 30px;
            font-size: var(--title-font-size);
            font-weight: $bold-font-weight;

            @include mq-up(pc) {
              padding: 58px 0 40px;
              font-size: 20px;
            }
          }

          &__text {
            padding: 0 28px;
            text-align: left;
            min-height: 7em;
            @include mq-up(pc) {
              text-align: center;
            }
          }

          &__img {
            width: 245px;
            margin: 30px auto;

            @include mq-up(pc) {
              width: 300px;
              margin: 40px 0 50px;
            }
          }

          &__percentage-list {
            margin: 0 0 6px 18px;

            &__item {
              display: inline-block;
              position: relative;

              &__circle-icon {
                width: 18px;
                height: 18px;
                display: inline-block;
                position: absolute;
                top: 4px;
                left: -24px;
                border-radius: 9px;

                @include mq-up(pc) {
                  top: 7px;
                }
              }
            }
          }

          &__annotation {
            padding: 0 28px;
            line-height: 24px;
          }
        }
      }
    }

    //----- wide-contents -----

    &__wide-contents {
      //----- Employment -----

      &__text {
        text-align: left;

        @include mq-up(pc) {
          text-align: center;
        }
      }

      &__x-scroll {
        width: 100%;
        margin: 44px auto;
        overflow-x: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include mq-up(pc) {
          margin: 33px auto;
          justify-content: flex-start;
          flex-wrap: wrap;
          max-width: 1480px;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        &__img {
          height: 84px;
          max-width: initial;
          width: auto;
          display: block;
          margin: 0 0;
          @include mq-up(pc) {
            height: 118px;
            margin: 0 auto;
          }
        }
      }
    }

    //----- /wide-contents -----
  }

  &__footer-wrap {
    padding: 44px 27px 53px;
    background-color: $main-color;
    color: $white;

    a{
      color: $white !important;
    }
    &__container {
      @include mq-up(pc) {
        width: 77%;
        max-width: 1200px;
        margin: 0 auto;
      }

      &__info-wrap {
        @include mq-up(pc) {
          padding: 45px 0 85px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        &__img-wrap {
          &__img {
            width: 125px;
            display: block;

            @include mq-up(pc) {
              width: 180px;
            }
          }
        }

        &__info {
          padding: 45px 0;

          @include mq-up(pc) {
            padding: 0;
            font-size: 13px;
            line-height: 26px;
          }

          &__tell {
            color: $white;
          }
        }
      }

      &__line {
        opacity: 0.3;
      }

      &__menu {
        padding-top: 45px;

        @include mq-up(pc) {
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        &__item {
          color: $white;
          display: block;

          @include mq-up(pc) {
            font-size: 12px;
            font-weight: $bold-font-weight;
          }

          &:last-child {
            font-size: var(--minimum-font-size);
            text-align: right;
            margin-top: -22px;
            font-weight: $base-font-weight;

            @include mq-up(pc) {
              margin-top: 0;
              margin-left: 60px;
            }
          }
        }
      }
    }
  }
}


.customize-support {
  .main{
    .header-hp {
      top: 46px;
      @include mq-up(pc) {
        top: 32px;
      }
    }
  }


  #wpadminbar {
    position: fixed;
    top: 0;
    z-index: 999;
  }
}
.main{
  .header-hp {
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    z-index: 9999;

    @include mq-up(pc) {
      height: 80px;
    }

  }


  .main__privacy{
    .link{
      text-decoration: underline;
      color: #0c6ca0;

    }
  }
  .main__privacy__list-container__item__dl{
    margin-top: 10px;
  }
  .main__privacy__list-container__item__dt{
    font-weight: 600;
  }
  .main__privacy__list-container__item__dd{
    padding-left: 1em;
    margin-bottom: 10px;
  }
}


@import "./page/top";
@import "./page/price";
@import "./page/course";
@import "./page/support";
@import "./page/voice";
@import "./page/inquiry";

