.hp-voice{

  &__sort{
    margin: 0 auto;
    max-width: 600px;
    padding: 30px;
    display: block;
    border-radius: 10px;
    background: #f0f0f0;
  }

  &__block{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include mq-down(m){
      gap: 10px;
    }
    input{
      display: none;
    }
    label{
      border-radius: 40px;
      overflow: hidden;
    }
    input[type = radio] + span{
      cursor: pointer;
      display: block;
      padding: 5px 20px;
      background: white;
      color: $main-color;
      font-size: 12px;
    }
    input[type = radio]:checked + span{
      cursor: pointer;
      background: $key-color;
      color: white;
    }
  }
  &__block--dt{
    width: 80px;
    padding-top: 4px;
    font-weight: 600;
    font-size: 14px;
    @include mq-down(m){
      width: 100%;
    }
  }
  &__block--dd{
    width: calc(100% - 80px);
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @include mq-down(m){
      width: 100%;
    }
  }
  .button{
    margin-top: 30px;
  }
  .button__text{
    font-size: 14px;
  }
}