.hp-course{
  //padding-bottom: 80px;
  .c-inner{
    @include mq-down(m){
      overflow: hidden;
    }

  }
  .main__course__list-container__item__info{
    @include mq-down(m){
      padding: 40px 20px 0;

      .main__course__list-container__item__info__number{
        padding-top: 0;
      }
    }
  }
  .l-cv{
    .c-inner{
      @include mq-down(m){
        overflow: initial;
      }
    }
  }
  .b-support__img{
    @include mq-down(m){
      max-width: 150px;
      margin: 0 auto;
      display: block;
    }
  }
  .b-flow{
    margin-bottom: 40px;
    &__img{
      margin-bottom: 20px;
    }
    &__title{
      min-height: initial;
    }
    &__txt{
      font-size: 1.4rem;
    }

  }
  .c-edit{
    p{
      line-height: 1.8 !important;
    }
    img{
      margin: 0 auto !important;
    }
    h3{
      margin-top: 0;
      margin-bottom: 20px;
    }

  }
  .b-student__container{
    padding-top: 0;
    @include mq-down(m){
      overflow: initial !important;
    }
  }
  .b-student:before{
    width: 100vw;
    @include mq-down(m){
      width: 110vw;
    }
  }
  .main__course__point-list__item{
    padding-left: 0;
  }

  &__slider{

    &__img{
      object-fit: cover;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
    }

    &__icon{
      margin: 0 auto !important;
      display: block;
      max-width: 120px !important;
    }

    &__txt{
      margin-top: 15px;
      text-align: center;
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  &__column{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    width: 100%;
    &--item{
      width: calc(33% - 20px);
      max-width: 230px;
      @include mq-down(m){
        width: calc(50% - 20px);
      }
    }
  }

}