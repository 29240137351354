.hp-inquiry{
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap:0 60px;
  padding-bottom: 100px;
  @include mq-down(xl){
    padding: 0 20px 80px;
  }
  .l-contact__step{
    margin-top: 0;
  }
  &__caution{
    margin: 10px 0;
    font-size: 1.4rem;
    @include mq-down(xl){
      margin-bottom: 40px;
    }
  }
  &__title{
    width: 100%;
    font-size: 3rem;
    background: #f0f0f0;
    padding:5px 20px;
    font-weight: bold;
    margin: 20px 0 40px;
    @include mq-down(xl){
      font-size: 2.8rem;
    }
    @include mq-down(m){
      font-size: 2.0rem;
    }
  }
  &__col1{
    width: calc(45% - 30px);
    @include mq-down(xl){
      width: 100%;
    }

  }
  &__img{
    width: 100%;
    position: relative;
    //display: block;
    &.is-img-pc{
      display: block;
    }
    &.is-img-sp{
      display: none;
    }
    @include mq-down(xl){
      &.is-img-pc{
        display: none;
      }
      &.is-img-sp{
        display: block;
      }
    }
  }
  &__col2{
    width: calc(55% - 30px);
    @include mq-down(xl){
      width: 100%;
    }
  }
}